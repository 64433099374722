import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@gbm/queen-ui-guidelines';

export interface ConfirmManualReviewDialogProps {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  loading: boolean;
  error: boolean;
}

const ConfirmManualReviewDialog: FC<ConfirmManualReviewDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
  error,
}) => {
  return (
    <Dialog
      id="individualInstancesDialog"
      data-testid="individualInstancesDialog"
      open={isOpen}
    >
      <DialogTitle> Confirm save manual verification</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure that you want to save the review?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          id="btnCancelDialog"
          data-testid="btnCancelDialog"
          disabled={loading}
          onClick={onClose}
          color="light"
        >
          Cancel
        </Button>

        <Button
          autoFocus
          id="btnConfirmDialog"
          data-testid="btnConfirmDialog"
          disabled={loading}
          loading={loading}
          onClick={onConfirm}
        >
          {error ? 'Retry' : 'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmManualReviewDialog;
