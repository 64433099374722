import { CSSProperties, FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import PlaceholdersTable from 'components/PlaceholdersTable';
import { CountryData, DetailMatchCountries } from 'types/Transaction';
import { formatDateTimeTZUser } from 'utils/dates';
import styles from './styles.module.scss';
import { getStatusToDisplay } from 'utils/transactionsUtils';

type TransactionResultCountriesProps = {
  selectedCountryData: CountryData | undefined;
  handleOpenModalDetailData: (extraData: any) => void;
};

const TransactionResultCountries: FC<TransactionResultCountriesProps> = ({
  selectedCountryData,
  handleOpenModalDetailData,
}) => {
  const [controlledSwiper] = useState();
  const matchDetails: DetailMatchCountries[] = selectedCountryData?.validator
    .matchDetail as DetailMatchCountries[];

  const renderMatchDetail = () => {
    if (matchDetails) {
      if (matchDetails.length > 0) {
        return (
          <>
            <Margin side="bottom" xs={12}>
              <div className={styles['swipper-container']}>
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={50}
                  navigation
                  pagination
                  controller={{ control: controlledSwiper }}
                  style={
                    {
                      '--swiper-navigation-color': '#3BAFDA',
                      '--swiper-navigation-size': '20px',
                      '--swiper-pagination-color': '#3BAFDA',
                      '--swiper-pagination-bullet-inactive-color': '#999999',
                      '--swiper-pagination-bullet-inactive-opacity': '1',
                      '--swiper-pagination-bullet-size': '10px',
                      '--swiper-pagination-bullet-horizontal-gap': '6px',
                      '--swiper-pagination-top': '80px',
                    } as CSSProperties
                  }
                >
                  {matchDetails?.map((detail) => {
                    const matchDetail: DetailMatchCountries =
                      detail as DetailMatchCountries;
                    return (
                      <SwiperSlide
                        style={{ paddingInline: '40px' }}
                        key={nanoid()}
                      >
                        <Grid row>
                          <Grid sm={12} xl={12}>
                            <Margin side="bottom" xs={48}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Country:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>{matchDetail.country}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        List:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {matchDetail.listName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        List level:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {matchDetail?.listLevel}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={nanoid()}>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Created at:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>
                                        {formatDateTimeTZUser(
                                          matchDetail?.createdAt ?? '',
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={nanoid()}>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Updated at:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>
                                        {formatDateTimeTZUser(
                                          matchDetail?.updatedAt ?? '',
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Margin>
                          </Grid>
                        </Grid>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </Margin>
          </>
        );
      }
    }

    return <></>;
  };

  const renderContent = () => {
    return (
      <>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {getStatusToDisplay(selectedCountryData?.validator.match)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match action:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {getStatusToDisplay(selectedCountryData?.validator.matchAction)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match level:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{selectedCountryData?.validator.matchLevel}</Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match detail:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{matchDetails?.length}</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction result
          </Typography>
          <Button
            color="link"
            disabled={true}
            onClick={() =>
              handleOpenModalDetailData(selectedCountryData?.details)
            }
          >
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <Table>
          <TableBody>{renderContent()}</TableBody>
        </Table>
        {renderMatchDetail()}
      </Margin>
    </>
  );
};

export default TransactionResultCountries;
