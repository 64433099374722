import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

type DividerName = {
  title: string;
  children: ReactNode;
};

const DividerName: FC<DividerName> = ({ title, children }) => {
  return (
    <div>
      <div className={styles.dividerContainer}>
        <div className={styles.textTitle}>{title}</div>
      </div>
      <div className={styles.wraperPanel}>{children}</div>
    </div>
  );
};

export default DividerName;
