import { useReducer, createContext, ReactNode, FC, Children } from 'react';
import { TransactionsState } from 'types/TransactionsState';

import reducer, { initialState } from './reducer';

export const TransactionsContext = createContext<{
  state: TransactionsState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface TransactionsProps {
  children: ReactNode;
}
const Transactions: FC<TransactionsProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TransactionsContext.Provider value={{ state, dispatch }}>
      {Children.only(children)}
    </TransactionsContext.Provider>
  );
};
export default Transactions;
