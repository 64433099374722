import { FC, useEffect, useState } from 'react';
import { DropdownOptions } from 'types/DropdownOptions';
import { ListMatchingData, ValidatorManualReview } from 'types/Transaction';
import {
  getColorForBadge,
  getStatusToDisplay,
  TransactionTypes,
} from 'utils/transactionsUtils';
import { Margin, Grid, Badge, Notification } from '@gbm/queen-ui-guidelines';
import DropdownController from 'components/common/DropdownController';
import TransactionInformationListMatching from './TransactionInformationListMatching';
import TransactionResultListMatching from './TransactionResultListMatching';
import capitalize from 'lodash/capitalize';
import ModalDetailData from '../ModalDetailData';
import ButtonsValidation from 'components/ButtonsValidation';
import ModalVerification from '../ModalVerification';
import { useDispatcherTransactions } from 'providers/Transactions/useDispatcher';
import styles from './styles.module.scss';
import { TransactionStatus } from 'constants/transactionStatus';
import { TransactionValidationToSave } from 'types/TransactionValidationToSave';
import ModalDetailVerification from '../ModalDetailVerification';
import { nanoid } from 'nanoid';

type ListMatchingValidatorProps = {
  listMatchingData?: ListMatchingData[] | null;
  updateValidationsToFill: Function;
  isSelectedValidatorFilled: (selectedTransactionId: string) => boolean;
  transactionStatus: string | undefined;
};

const ListMatchingValidator: FC<ListMatchingValidatorProps> = ({
  listMatchingData,
  updateValidationsToFill,
  isSelectedValidatorFilled,
  transactionStatus,
}) => {
  const [isOpenDetailModalData, setIsOpenDetailModalData] =
    useState<boolean>(false);
  const [selectedListMatching, setSelectedListMatching] =
    useState<ListMatchingData>();
  const [optionsListMatching, setOptionsListMatching] =
    useState<DropdownOptions[]>();
  const [selectedOptionListMatching, setSelectedOptionListMatching] =
    useState<DropdownOptions | null>(null);
  const [isOpenModalVerification, setIsOpenModalVerification] = useState(false);
  const { addTransactionToSave } = useDispatcherTransactions();
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] =
    useState<boolean>(false);
  const [isOpenModalDetailVerification, setIsOpenModalDetailVerification] =
    useState<boolean>(false);
  const [extraInfo, setExtraInfo] = useState<any>();

  useEffect(() => {
    if (selectedOptionListMatching) {
      const listMatching = listMatchingData?.find(
        (listMatching: ListMatchingData) =>
          selectedOptionListMatching?.id === listMatching.frontendId,
      );

      if (listMatching) {
        setSelectedListMatching(listMatching);
      }
    }
  }, [selectedOptionListMatching]);

  useEffect(() => {
    if (listMatchingData) {
      const options = listMatchingData.map((listMatching, i) => ({
        label: `${capitalize(listMatching?.partyType)} - ${getStatusToDisplay(
          listMatching?.validator.matchAction,
        )}`,
        displayName: `${capitalize(
          listMatching?.partyType,
        )} - ${getStatusToDisplay(listMatching?.validator.matchAction)}`,
        name: `${listMatching?.partyType}-${listMatching?.validator.matchAction}`,
        value: `${listMatching?.frontendId}`,
        id: `${listMatching?.frontendId}`,
      }));
      setOptionsListMatching(options);
      setSelectedListMatching(listMatchingData[0]);
      setSelectedOptionListMatching(options[0]);
    }
  }, [listMatchingData]);

  const handleOpenModalDetailData = (extraData: any) => {
    setExtraInfo(extraData);
    setIsOpenDetailModalData(true);
  };

  const handleAddVerification = (dataValidation: any) => {
    const data: ValidatorManualReview = {
      code: TransactionTypes.LIST_MATCHING,
      status: dataValidation?.dropdownStatus?.id,
      details: {
        comments: dataValidation?.comment,
        complementaryComments: dataValidation?.supplementaryComment,
      },
      requestId: selectedListMatching?.validator?.requestId,
      labelToShow: `${capitalize(
        selectedListMatching?.partyType,
      )} - ${getStatusToDisplay(selectedListMatching?.validator.matchAction)}`,
      id: '',
    };
    addTransactionToSave({
      validator: TransactionTypes.LIST_MATCHING,
      data: { ...data },
    });
    updateValidationsToFill(
      (validationToFill: TransactionValidationToSave[]) => {
        const validationToFillCopy = [...validationToFill];

        const indexOfElement = validationToFillCopy.findIndex(
          (validation: TransactionValidationToSave) =>
            validation.id === selectedListMatching?.validator?.requestId,
        );
        if (indexOfElement !== -1) {
          validationToFillCopy[indexOfElement].completed = true;
        }
        return validationToFillCopy;
      },
    );
    setIsOpenModalVerification(false);
    setIsOpenSuccessMessage(true);
    setTimeout(() => {
      setIsOpenSuccessMessage(false);
    }, 3000);
  };

  const disableAddValidationButton = () => {
    return !(
      selectedListMatching?.validator.matchAction ===
        TransactionStatus.manualReview &&
      !isSelectedValidatorFilled(
        selectedListMatching?.validator.requestId ?? '',
      ) &&
      transactionStatus === TransactionStatus.manualReview
    );
  };

  return (
    <>
      <Grid>
        <Margin side="bottom" xs={24}>
          <Margin side="bottom" xs={24}>
            <div className="d-flex justify-content-between align-items-center w-full">
              <div>
                <DropdownController
                  id="selectCountriesValidator"
                  name="selectCountriesValidator"
                  label="List matching validations"
                  placeholder={
                    selectedOptionListMatching
                      ? selectedOptionListMatching?.displayName ?? ''
                      : 'Select list matching'
                  }
                  options={optionsListMatching}
                  required={false}
                  onChange={setSelectedOptionListMatching}
                />
                <Badge
                  className={styles.badge}
                  color={getColorForBadge(
                    selectedListMatching?.validator.matchAction ?? '',
                  )}
                  variant="pill"
                >
                  {getStatusToDisplay(
                    selectedListMatching?.validator.matchAction,
                  )}
                </Badge>
              </div>

              <ButtonsValidation
                onAddValidation={() => setIsOpenModalVerification(true)}
                onSeeValidation={() => setIsOpenModalDetailVerification(true)}
                isDisabledSeeValidationAction={
                  !selectedListMatching?.manualReview
                }
                isDisabledAddValidationAction={disableAddValidationButton()}
              />
            </div>
          </Margin>
          <Margin side="bottom" xs={24}>
            <Grid row>
              <Grid sm={12} xl={6}>
                <TransactionInformationListMatching
                  selectedListMatchingData={selectedListMatching}
                  handleOpenModalDetailData={handleOpenModalDetailData}
                />
              </Grid>
              <Grid sm={12} xl={6}>
                <TransactionResultListMatching
                  selectedListMatchingData={selectedListMatching}
                  handleOpenModalDetailData={handleOpenModalDetailData}
                />
              </Grid>
            </Grid>
          </Margin>
        </Margin>
      </Grid>
      <ModalDetailData
        onClose={() => setIsOpenDetailModalData(false)}
        extraInfo={extraInfo}
        isOpen={isOpenDetailModalData}
      />
      <ModalVerification
        onClose={() => {
          setIsOpenModalVerification(false);
        }}
        onSubmit={handleAddVerification}
        isOpen={isOpenModalVerification}
      />
      <ModalDetailVerification
        onClose={() => setIsOpenModalDetailVerification(false)}
        isOpen={isOpenModalDetailVerification}
        user={selectedListMatching?.userManualReview}
        manualReview={selectedListMatching?.manualReview}
      />
      <Notification open={isOpenSuccessMessage} color="success" colorInherit>
        The manual review has been added successfully
      </Notification>
    </>
  );
};

export default ListMatchingValidator;
