import { FC } from 'react';
import {
  Analysis,
  AnalysisDays,
  DetailMatchTransactionalAnalysis,
} from 'types/Transaction';
import { Typography, Grid, Margin, Tooltip } from '@gbm/queen-ui-guidelines';
import {
  BlockIcon,
  CheckmarkCircleIcon,
  WarningIcon,
  NoInfoIcon,
} from 'components/Icons';
import { nanoid } from 'nanoid';
import styles from './styles.module.scss';
import { TransactionAnalysisStatus } from 'constants/transactionAnalysisStatus';

type SummaryAlertsProps = {
  matchDetail: DetailMatchTransactionalAnalysis;
};
const SummaryAlerts: FC<SummaryAlertsProps> = ({ matchDetail }) => {
  const analyseWarningInfo = (analysis: Analysis[] | AnalysisDays[]) => (
    <div className={styles.containerIcons}>
      {
        // eslint-disable-next-line react/destructuring-assignment
        analysis?.map(({ warning, analysisStatus }) => {
          const key = nanoid();

          if (analysisStatus !== TransactionAnalysisStatus.ok) {
            if (
              analysisStatus ===
                TransactionAnalysisStatus.notFoundProfileData ||
              analysisStatus ===
                TransactionAnalysisStatus.notFoundProfileThresholdData
            ) {
              return (
                <Tooltip position="top" message="Missing information" key={key}>
                  <NoInfoIcon />
                </Tooltip>
              );
            }
            return (
              <Tooltip
                position="top"
                message="Analysis not performed"
                key={key}
              >
                <BlockIcon />
              </Tooltip>
            );
          }

          if (!warning) {
            return (
              <Tooltip
                position="top"
                message="Rule under compliance."
                key={key}
              >
                <CheckmarkCircleIcon />
              </Tooltip>
            );
          }

          return (
            <Tooltip position="top" message="Rule breaking" key={key}>
              <WarningIcon />
            </Tooltip>
          );
        })
      }
    </div>
  );

  return (
    <>
      <Margin side="bottom" xs={24}>
        <Typography component="strong" variant="header">
          Summary Analysis
        </Typography>
      </Margin>
      <Margin side="bottom" xs={24}>
        <Grid row justiry="center">
          <Grid md={6}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Typography variant="subHeader"> Analysis Override</Typography>
              {analyseWarningInfo(matchDetail.analysisOverride)}
            </div>
          </Grid>
          <Grid md={6}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Typography variant="subHeader">
                Beneficiary 90 days restricted rule
              </Typography>
              {analyseWarningInfo(matchDetail.analysisDays)}
            </div>
          </Grid>
        </Grid>
      </Margin>

      <Grid row justify="center">
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W0 Alerts</Typography>
            {analyseWarningInfo(matchDetail.analysisW0)}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W1 Alerts</Typography>
            {analyseWarningInfo(matchDetail.analysisW1)}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W2 Alerts</Typography>
            {analyseWarningInfo(matchDetail.analysisW2)}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryAlerts;
