import { envConfig } from 'config';
import request from '.';
import { RequestConfig } from 'types/RequestConfig';

const API_BASE_URL = `${envConfig.API_BASE}`;

export function getUserRequest(userId: string, options = {}) {
  return request(`${API_BASE_URL}/v1/users/login/${userId}`, {
    method: 'GET',
    ...options,
  } as RequestConfig);
}
