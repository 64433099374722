import { FunctionComponent, ReactElement, Children } from 'react';
import AccessManagementPermission from './AccessManagementPermission';
import AccessManagementRole from './AccessManagementRole';
import AccessManagementSection from './AccessManagementSection';
import AccessManagementUserRole from './AccessManagementUserRole';
import Transactions from './Transactions';
import FeatureProvider from './FeatureProvider';
import Compose from './ProviderComposer';

interface ProvidersProps {
  children: ReactElement;
  features: Array<string>;
}

const Providers: FunctionComponent<ProvidersProps> = ({
  children,
  features,
}) => (
  <FeatureProvider features={features}>
    <Compose
      components={[
        AccessManagementPermission,
        AccessManagementRole,
        AccessManagementSection,
        AccessManagementUserRole,
        Transactions,
      ]}
    >
      {Children.only(children)}
    </Compose>
  </FeatureProvider>
);

export default Providers;
