import { FunctionComponent, PropsWithChildren } from 'react';

interface ComposeProps extends PropsWithChildren {
  components: Array<any>;
}
const Compose: FunctionComponent<ComposeProps> = ({
  components = [],
  children,
}) =>
  components.reduceRight(
    (acc, Comp: React.ElementType) => <Comp>{acc}</Comp>,
    children,
  );
export default Compose;
