import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/scss/theme.scss';
import 'react-dates/initialize';
import '@gbm/queen-ui-guidelines/css/index.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
