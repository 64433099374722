import { useState, useEffect, useCallback, FC } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@gbm/queen-ui-guidelines';

import { WARNING_TIME_S } from 'constants/auth';
import {
  onIdleTimeout,
  isSessionExpired,
  setActive,
  shouldWarn,
} from 'utils/auth';
interface SessionTimeoutDetectorProps {
  onContinue: Function;
  onIdleSessionExpired: Function;
}
const SessionTimeoutDetector: FC<SessionTimeoutDetectorProps> = ({
  onContinue,
  onIdleSessionExpired,
}) => {
  const [displayWarning, setDisplayWarning] = useState(false);

  const checkIdleTimeout = useCallback(() => {
    if (onIdleTimeout() && !displayWarning && !shouldWarn()) {
      setDisplayWarning(true);
    }

    if (isSessionExpired()) {
      onIdleSessionExpired();
    }
  }, [displayWarning, onIdleSessionExpired]);

  useEffect(() => {
    const interval = setInterval(checkIdleTimeout, WARNING_TIME_S);

    return () => clearInterval(interval);
  }, [checkIdleTimeout]);

  const continueSession = () => {
    setActive();
    onContinue();
    setDisplayWarning(false);
  };

  return (
    <Dialog
      id="dialogSessionTimeoutDetector"
      data-testid="dialogSessionTimeoutDetector"
      open={displayWarning}
    >
      <DialogTitle>
        The session will be finished in less than 5 minutes.
      </DialogTitle>

      <DialogContent>
        Do you want to extend the time of the session?
      </DialogContent>
      <DialogActions>
        <Button
          id="btnCancelSessionTimeoutDetector"
          data-testid="btnCancelSessionTimeoutDetector"
          color="light"
          onClick={() => {
            setDisplayWarning(false);
            setActive();
          }}
        >
          Cancel
        </Button>

        <Button
          id="btnContinueSessionTimeoutDetector"
          data-testid="btnContinueSessionTimeoutDetector"
          onClick={continueSession}
        >
          Continue in session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDetector;
