import { Button } from '@gbm/queen-ui-guidelines';
import { FaCheck, FaPlusCircle } from 'react-icons/fa';
import Feature from 'providers/FeatureProvider/Feature';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { FC } from 'react';

type ButtonsValidationProps = {
  onAddValidation: () => void;
  onSeeValidation?: () => void;
  isDisabledAddValidationAction?: boolean;
  isDisabledSeeValidationAction?: boolean;
};

const ButtonsValidation: FC<ButtonsValidationProps> = ({
  onAddValidation,
  onSeeValidation,
  isDisabledAddValidationAction,
  isDisabledSeeValidationAction,
}) => {
  return (
    <div
      className={clsx(styles['buttons-container'], 'd-flex align-items-center')}
    >
      <Feature name="transactionReview">
        <Button
          onClick={onAddValidation}
          disabled={isDisabledAddValidationAction ?? false}
        >
          <FaPlusCircle /> Add validation
        </Button>
      </Feature>

      <Feature name="transactionReview">
        <Button
          onClick={onSeeValidation}
          disabled={isDisabledSeeValidationAction ?? false}
        >
          <FaCheck /> Validation
        </Button>
      </Feature>
    </div>
  );
};

export default ButtonsValidation;
