import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/routines';

import { GET_ROLES, CREATE_ROLE, EDIT_ROLE, DELETE_ROLE } from './constants';

export const getRoles = createRoutine(GET_ROLES);
export const createRole = extendRoutine(createRoutine(CREATE_ROLE), 'RESET');
export const editRole = extendRoutine(createRoutine(EDIT_ROLE), 'RESET');
export const deleteRole = extendRoutine(createRoutine(DELETE_ROLE), 'RESET');
