import { type PropsWithChildren, type FC, useState, ReactElement } from 'react';
import { Grid } from '@gbm/queen-ui-guidelines';
import { FaShieldAlt, FaBell } from 'react-icons/fa';
import { IoIosSettings } from 'react-icons/io';

import clsx from 'clsx';

import { ROUTES } from 'constants/routes';
import { SideNavItem } from 'types/SideNavItem';
import { useFeature } from 'providers/FeatureProvider';
import Navbar from 'components/Navbar';
import SideNav from 'components/SideNav';
import './styles.scss';

interface LayoutProps extends PropsWithChildren {
  children: ReactElement;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const [small, setSmall] = useState(false);

  const navItems: Array<SideNavItem> = [
    {
      icon: <FaBell />,
      to: ROUTES.ALERTS,
      label: 'Transactions',
      hasPermission: useFeature('transactionsRead'),
    },
    {
      icon: <IoIosSettings />,
      to: ROUTES.CONFIGURATIONS,
      label: 'Configurations',
      hasPermission: useFeature('configurationsRead'),
    },
    {
      icon: <FaShieldAlt />,
      to: ROUTES.ACCESS_MANAGEMENT,
      label: 'Access Management',
      hasPermission: useFeature('accessManagementVisualizeSection'),
    },
  ];

  return (
    <>
      <Navbar title="VAN" onSmall={() => setSmall(!small)} />
      <div className="app-container">
        <SideNav key="sideNav" small={small} content={navItems} />
        <div className={clsx('contentPage')}>
          <main>
            <Grid>{children}</Grid>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
