import { FC, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Spinner } from '@gbm/queen-ui-guidelines';

import { INACTIVE_TIMEOUT } from 'constants/auth';
import { ROUTES } from 'constants/routes';
import ViewSignOut from 'components/ViewSignOut';
import SessionTimeoutDetector from 'components/SessionTimeoutDetector';
import Backdrop from 'components/Backdrop';
import Layout from 'components/Layout';
import PageError from 'components/common/PageError';
import Providers from 'providers';
import { usePrivateRoute } from 'hooks/usePrivateRoute';
import { signOut } from 'utils/auth';

import styles from './styles.module.scss';

interface PrivateRouteProps {
  component: React.ElementType;
  path: string;
  exact?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component, path, exact }) => {
  const { token, permissions, state, actions } = usePrivateRoute();

  const Component = component;

  if (state.loading) {
    return (
      <Backdrop
        variant="transparent"
        id="spinnerLoading"
        data-testid="spinnerLoading"
      >
        <Spinner size="sm" color="info" />
      </Backdrop>
    );
  }

  if (token) {
    return state.isLoggedOut ? (
      <ViewSignOut
        noPermissions={!permissions?.length}
        inactiveTimeout={INACTIVE_TIMEOUT}
        onSetInactive={signOut}
      />
    ) : (
      <Route
        exact={exact}
        path={path}
        render={(props) => (
          <Providers features={permissions}>
            <Layout>
              <ErrorBoundary
                fallback={
                  <div className={styles.errorContainer}>
                    <PageError
                      id="msgGlobalError"
                      labelAction="Go home"
                      onAction={() => window.location.replace(ROUTES.ALERTS)}
                      message="An error has occurred. Please go back to the home page."
                    />
                  </div>
                }
              >
                <Suspense fallback={<Spinner size="sm" color="info" />}>
                  <Component {...props} />
                  <SessionTimeoutDetector
                    onIdleSessionExpired={() => actions.setIsLoggedOut(true)}
                    onContinue={actions.refreshToken}
                  />
                </Suspense>
              </ErrorBoundary>
            </Layout>
          </Providers>
        )}
      />
    );
  }

  return actions.searchLocation.includes('code') ? null : (
    <Route
      exact={exact}
      path={path}
      render={() => <Redirect to={ROUTES.SIGN_IN} />}
    />
  );
};

export default PrivateRoute;
