import { type FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Icon,
  Dropdown,
  DropdownItem,
  Badge,
  Typography,
} from '@gbm/queen-ui-guidelines';

import { getTokenUsername } from 'utils/auth';
import { formatEnv } from 'utils/formatEnvironment';

import './styles.scss';

export interface NavbarProps {
  title: string;
  onSmall: () => void;
}

const Navbar: FC<NavbarProps> = ({ title, onSmall }) => {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const userName = getTokenUsername();

  const handleToggleDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleCloseDropdown = () => {
    setUserDropdownOpen(false);
  };

  return (
    <nav className="navbar" role="toolbar">
      <div className="navbar--brand">
        <div
          data-testid="btnLogoMunus"
          className="navbar--logo"
          onClick={onSmall}
        >
          <img width={25} height={25} src="/imgs/VAN-icon.png" alt="VAN Icon" />
        </div>
        <Link className="navbar--label-container" to="/">
          <Typography className="navbar--label-container-label">
            {title}
          </Typography>
          {process.env.REACT_APP_ENV !== 'prd' && (
            <Badge color="warning" lighten>
              {formatEnv(process.env.REACT_APP_ENV)} 2.0
            </Badge>
          )}
        </Link>
      </div>

      <Dropdown
        data-testid="dropdownUserLogout"
        variant="button"
        right
        className=""
        label={userName}
        color="light"
        onOpen={handleToggleDropdown}
        onClose={handleCloseDropdown}
        open={userDropdownOpen}
        iconButton={
          <Icon
            className={`fas fa-chevron-${
              userDropdownOpen ? 'up' : 'down'
            } ml-1`}
          />
        }
      >
        <DropdownItem data-testid="btnLogout" className="sign-out">
          <Link to="/sign-out" className="dropdown-item notify-item">
            <Icon className="remixicon-logout-box-line mr-2" />
            <span>Logout</span>
          </Link>
        </DropdownItem>
      </Dropdown>
    </nav>
  );
};

export default Navbar;
