import { TableRow, TableCell, Skeleton } from '@gbm/queen-ui-guidelines';
import { FunctionComponent } from 'react';

interface PlaceholdersTableProps {
  placeholderRows: number;
  placeholderCols: number;
  width: string;
}

export const PlaceholdersTable: FunctionComponent<PlaceholdersTableProps> = ({
  placeholderRows,
  placeholderCols,
  width,
}) => (
  <>
    {[...Array(placeholderRows)].map((row, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={`tr-${i}`}>
        {[...Array(placeholderCols)].map((col, j) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={`tr-${i}-td-${j}`}>
            <Skeleton height={15} width={width} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default PlaceholdersTable;
