import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/routines';

import {
  CREATE_PERMISSION,
  EDIT_PERMISSION,
  DELETE_PERMISSION,
} from './constants';

export const createPermission = extendRoutine(
  createRoutine(CREATE_PERMISSION),
  'RESET',
);
export const editPermission = extendRoutine(
  createRoutine(EDIT_PERMISSION),
  'RESET',
);
export const deletePermission = extendRoutine(
  createRoutine(DELETE_PERMISSION),
  'RESET',
);
