import { useContext } from 'react';

import {
  getTransactionsRequest,
  getTransactionRequest,
  manualReviewTransactionRequest,
  setStatusToTransactionRequest,
} from 'utils/request/transactions';
import type { ManualReviewBody } from 'utils/request/transactions';

import { TransactionsContext } from './index';
import * as actions from './actions';
import { BasicReviewValidation, Transaction } from 'types/Transaction';
import {
  ADD_MANUAL_REVIEW_TRANSACTION,
  UPDATE_MANUAL_REVIEW_TRANSACTION,
} from './constants';

let alertsDispatcher: any;

async function addTransactionToSave(payload: any) {
  alertsDispatcher({
    type: ADD_MANUAL_REVIEW_TRANSACTION,
    payload,
  });
}

async function updateTransactionToSave(payload: any) {
  alertsDispatcher({
    type: UPDATE_MANUAL_REVIEW_TRANSACTION,
    payload,
  });
}

async function getTransactions() {
  try {
    alertsDispatcher(actions.getTransactions.request());

    const { items } = await getTransactionsRequest();

    alertsDispatcher(actions.getTransactions.success(items));
  } catch (error) {
    alertsDispatcher(actions.getTransactions.failure(error));
  }
}

async function getTransactionById(transactionId: string) {
  try {
    alertsDispatcher(actions.getTransaction.request());
    const transaction: Transaction = await getTransactionRequest(transactionId);
    alertsDispatcher(actions.getTransaction.success(transaction));
  } catch (error) {
    alertsDispatcher(actions.getTransaction.failure(error));
  }
}

async function createManualReview(
  transactionId: string,
  body: ManualReviewBody,
) {
  try {
    alertsDispatcher(actions.manualReviewTransaction.request());

    await manualReviewTransactionRequest(transactionId, body);

    alertsDispatcher(actions.manualReviewTransaction.success());
  } catch (error) {
    alertsDispatcher(actions.manualReviewTransaction.failure(error));
  }
}

async function setStatusToTransaction(
  bodyRequest: BasicReviewValidation,
  transactionId: string,
) {
  try {
    alertsDispatcher(actions.setStatusToTransaction.request());
    await setStatusToTransactionRequest(bodyRequest, transactionId);
    alertsDispatcher(actions.setStatusToTransaction.success());
  } catch (error) {
    alertsDispatcher(actions.setStatusToTransaction.failure(error));
  }
}

function resetGetTransactions() {
  alertsDispatcher(actions.getTransactions.reset());
}

function resetCreateManualReview() {
  alertsDispatcher(actions.manualReviewTransaction.reset());
}

function resetSetStatusToTransaction() {
  alertsDispatcher(actions.setStatusToTransaction.reset());
}
export function useDispatcherTransactions() {
  const { dispatch } = useContext(TransactionsContext);
  alertsDispatcher = dispatch;

  return {
    getTransactions,
    getTransactionById,
    resetGetTransactions,
    createManualReview,
    setStatusToTransaction,
    resetCreateManualReview,
    resetSetStatusToTransaction,
    addTransactionToSave,
    updateTransactionToSave,
  };
}
