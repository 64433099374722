import { FC } from 'react';
import {
  Margin,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@gbm/queen-ui-guidelines';
import ExtraInfo from '../ExtraInfo';
export interface ModalDetailDataProps {
  onClose: () => void;
  isOpen: boolean;
  extraInfo?: any;
}

const ModalDetailData: FC<ModalDetailDataProps> = ({
  onClose,
  isOpen,
  extraInfo,
}) => {
  return (
    <Modal
      id="modalDetailData"
      data-testid="modalDetailData"
      isOpen={isOpen}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader onClose={onClose}>Detail data</ModalHeader>
      <ModalBody>
        <Margin side="bottom" xs={24}>
          <ExtraInfo extraInfo={extraInfo} />
        </Margin>
      </ModalBody>
      <ModalFooter>
        <Button
          id="btnCloseModalComplementary"
          data-testid="btnCloseModalComplementary"
          type="button"
          color="light"
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDetailData;
