import { DisplayMessage, Button, Margin } from '@gbm/queen-ui-guidelines';
import { FunctionComponent } from 'react';
import styles from './styles.module.scss';

export interface PageErrorProps {
  id: string;
  message: string;
  labelAction: string;
  onAction: Function;
  variant?: string;
  others?: Array<string>;
}

const PageError: FunctionComponent<PageErrorProps> = ({
  id,
  message,
  labelAction,
  onAction,
  variant,
  ...others
}) => (
  <div id={id} data-testid={id} className={styles.pageError} {...others}>
    <Margin side="bottom" xs={24}>
      <DisplayMessage message={message} variant={variant || 'error'} />
    </Margin>

    <Button data-testid={`btn-${id}`} onClick={onAction}>
      {labelAction}
    </Button>
  </div>
);

export default PageError;
