import { Typography, Grid, Card, CardBody } from '@gbm/queen-ui-guidelines';
import { FC, useEffect } from 'react';
import IconSvg from './IconSvg';

import './styles.scss';

interface ViewSignOutProps {
  inactiveTimeout: number;
  onSetInactive: Function;
  noPermissions: Boolean;
}

const ViewSignOut: FC<ViewSignOutProps> = ({
  inactiveTimeout,
  onSetInactive,
  noPermissions,
}) => {
  let closeTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    closeTimeout = setTimeout(setInactive, inactiveTimeout);
  }, [inactiveTimeout]);

  const setInactive = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      closeTimeout = null;
      onSetInactive && onSetInactive();
    }
  };

  return (
    <Grid container className="mt-4" id="viewSignOut" data-testid="viewSignOut">
      <Grid row justify="center">
        <Grid md={8} lg={6} xl={5}>
          <Card>
            <CardBody className="p-5">
              <div className="text-center">
                <div className="mt-4">
                  <div className="logout-checkmark">
                    <IconSvg />
                  </div>
                </div>

                <Typography component="h3">Logging out</Typography>

                {noPermissions ? (
                  <Typography component="h5">
                    You do not have permissions to access VAN, please login with
                    another user
                  </Typography>
                ) : (
                  <Typography variant="textMuted">
                    Please, login again
                  </Typography>
                )}
              </div>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewSignOut;
