// TODO: Rid off moment and react-dates and use dayjs instead
// Check explanation on AdvanceSearch.tsx
import moment from 'moment-timezone';
import { isInclusivelyAfterDay } from 'react-dates';

import dayjs from 'libs/dayjs';

// TODO: Use this using dayjs isntead of moment
// export const maxDateToRangeDates = dayjs().add(0, 'months').add(1, 'day');
export const maxDateToRangeDates = moment().add(0, 'months').add(1, 'day');

export const isOutsideRangeInclusivelyAfterDay = (day: string) => {
  return isInclusivelyAfterDay(day, maxDateToRangeDates);
};

export const formatDateTimeTZUser = (value: string) => {
  return dayjs.utc(value).tz(dayjs.tz.guess()).format('DD/MM/YYYY hh:mmA');
};

export const formatDateTZUser = (value: string) => {
  return dayjs.utc(value).tz(dayjs.tz.guess()).format('DD/MM/YYYY');
};

export const formatDateUTC = (value: string) => {
  const date = dayjs.utc(value).format('DD/MM/YYYY');

  if (date === '01/01/1900') return 'NA';

  return date;
};
