export const GET_TRANSACTIONS = 'providers/Transactions/GET_TRANSACTIONS';
export const GET_TRANSACTION = 'providers/Transactions/GET_TRANSACTION';
export const MANUAL_REVIEW_TRANSACTION =
  'providers/Transactions/MANUAL_REVIEW_TRANSACTION';
export const SET_STATUS_TO_TRANSACTION =
  'providers/Transactions/SET_STATUS_TO_TRANSACTION';
export const ADD_MANUAL_REVIEW_TRANSACTION =
  'providers/Transactions/ADD_MANUAL_REVIEW_TRANSACTION';
export const UPDATE_MANUAL_REVIEW_TRANSACTION =
  'providers/Transactions/UPDATE_MANUAL_REVIEW_TRANSACTION';
