import { FunctionComponent, ReactElement } from 'react';
import useFeature from './hooks/useFeature';

interface RenderFeatureProps {
  name: string;
  children: ReactElement | Function;
}

const RenderFeature: FunctionComponent<RenderFeatureProps> = ({
  name,
  children,
}) => {
  const hasFeature = useFeature(name);

  if (typeof children === 'function') return children(hasFeature);

  return hasFeature ? children : null;
};

export default RenderFeature;
