/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { AccessManagementSectionState } from 'types/AccessManagementSectionState';

import {
  getSections,
  createSection,
  editSection,
  deleteSection,
} from './actions';

export const initialState: AccessManagementSectionState = {
  sections: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  createSection: {
    loading: false,
    loaded: false,
    error: null,
  },

  editSection: {
    loading: false,
    loaded: false,
    error: null,
  },

  deleteSection: {
    loading: false,
    loaded: false,
    error: null,
  },
};

export interface AccessManagementSectionActions {
  type: string;
  payload: any;
}

const reducer = (
  state: AccessManagementSectionState,
  action?: AccessManagementSectionActions,
) =>
  produce(state, (draftProduce) => {
    if (!action) return draftProduce;

    const { type, payload } = action;
    const draft = draftProduce;
    switch (type) {
      case getSections.REQUEST:
        draft.sections.loading = true;
        draft.sections.loaded = false;
        draft.sections.error = null;
        break;

      case getSections.SUCCESS:
        draft.sections.loading = false;
        draft.sections.loaded = true;
        draft.sections.error = null;
        draft.sections.list = payload;
        break;

      case getSections.FAILURE:
        draft.sections.loading = false;
        draft.sections.loaded = false;
        draft.sections.error = payload;
        break;

      case createSection.REQUEST:
        draft.createSection.loading = true;
        draft.createSection.loaded = false;
        draft.createSection.error = null;
        break;

      case createSection.SUCCESS:
        draft.createSection.loading = false;
        draft.createSection.loaded = true;
        draft.createSection.error = null;
        break;

      case createSection.FAILURE:
        draft.createSection.loading = false;
        draft.createSection.loaded = false;
        draft.createSection.error = payload;
        break;

      case createSection.RESET:
        draft.createSection = { ...initialState.createSection };
        break;

      case editSection.REQUEST:
        draft.editSection.loading = true;
        draft.editSection.loaded = false;
        draft.editSection.error = null;
        break;

      case editSection.SUCCESS:
        draft.editSection.loading = false;
        draft.editSection.loaded = true;
        draft.editSection.error = null;
        break;

      case editSection.FAILURE:
        draft.editSection.loading = false;
        draft.editSection.loaded = false;
        draft.editSection.error = payload;
        break;

      case editSection.RESET:
        draft.editSection = { ...initialState.editSection };
        break;

      case deleteSection.REQUEST:
        draft.deleteSection.loading = true;
        draft.deleteSection.loaded = false;
        draft.deleteSection.error = null;
        break;

      case deleteSection.SUCCESS:
        draft.deleteSection.loading = false;
        draft.deleteSection.loaded = true;
        draft.deleteSection.error = null;
        break;

      case deleteSection.FAILURE:
        draft.deleteSection.loading = false;
        draft.deleteSection.loaded = false;
        draft.deleteSection.error = payload;
        break;

      case deleteSection.RESET:
        draft.deleteSection = { ...initialState.deleteSection };
        break;

      default:
        return draft;
    }
  });

export default reducer;
