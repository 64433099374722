import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  TableHead,
  TableColumn,
  Skeleton,
  Grid,
} from '@gbm/queen-ui-guidelines';
import startCase from 'lodash/startCase';
import { getBlacklistInfo } from 'utils/request/transactions';
import styles from './styles.module.scss';
import { DetailMatchListMatching } from 'types/Transaction';

export interface ModalComplementaryDataProps {
  onClose: () => void;
  isOpen: boolean;
  matchDetailSelected?: DetailMatchListMatching;
}

const ModalComplementaryData: FC<ModalComplementaryDataProps> = ({
  onClose,
  isOpen,
  matchDetailSelected,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['transactionComplementaryInfo'],
    queryFn: () => getBlacklistInfo(matchDetailSelected?.id),
    refetchOnWindowFocus: true,
  });

  const getPercentageCoincidenceValues = () => {
    if (matchDetailSelected?.coincidencePercent) {
      return Object.entries(matchDetailSelected?.coincidencePercent).map(
        (values) => {
          const property = values[0];
          const value = values[1];
          return (
            <>
              <Grid sm={12} md={3}>
                <Typography component="strong" variant="subHeader">
                  {startCase(property)}
                </Typography>
                <Typography>{`: ${value || '0'}`}%</Typography>
              </Grid>
            </>
          );
        },
      );
    }
  };

  return (
    <Modal
      id="modalDetailComplementary"
      data-testid="modalDetailComplementary"
      isOpen={isOpen}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader onClose={onClose}>Detail data</ModalHeader>
      <ModalBody>
        <Margin side="bottom" xs={24}>
          <Typography variant="headerTitle">Percentage Coincidence</Typography>
          <Grid row>{getPercentageCoincidenceValues()}</Grid>
        </Margin>

        <Margin side="bottom" xs={24}>
          <Typography variant="headerTitle">Additional information</Typography>
          <Grid row>
            <Grid sm={12} xl={12}>
              <Table>
                <TableHead variant="light">
                  <TableRow>
                    <TableColumn>Data</TableColumn>
                    <TableColumn>Value</TableColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell>
                          <Typography component="strong" variant="subHeader">
                            Positions
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data.positions || ''}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography component="strong" variant="subHeader">
                            Remarks
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data.remarks || ''}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography component="strong" variant="subHeader">
                            Source links
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <ul className={styles.listLinks}>
                            {data.sourceWebLink
                              ?.split(';')
                              .map((link: string) => (
                                <li key={link}>
                                  <a
                                    className={styles['listLinksWrap']}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={link}
                                  >
                                    {link}
                                  </a>
                                </li>
                              )) || ''}
                          </ul>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Margin>
      </ModalBody>
      <ModalFooter>
        <Button
          id="btnCloseModalComplementary"
          data-testid="btnCloseModalComplementary"
          type="button"
          color="light"
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalComplementaryData;
