import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import { GET_USER_ROLE, EDIT_USER_ROLE } from './constants';

export const getUserRole = extendRoutine(createRoutine(GET_USER_ROLE), 'RESET');
export const editUserRole = extendRoutine(
  createRoutine(EDIT_USER_ROLE),
  'RESET',
);
