import { nanoid } from 'nanoid';
import { FC } from 'react';
import { Validator } from 'types/Transaction';
import styles from './styles.module.scss';
import ValidatorItem from './ValidatorItem';
import { Skeleton, CardBox, SkeletonLoader } from '@gbm/queen-ui-guidelines';

type ValidatorsProps = {
  validators: Validator[] | undefined;
  setSelectedValidator: (validator: Validator) => void;
  selectedValidator: Validator | null;
  loading: boolean;
};
const Validators: FC<ValidatorsProps> = ({
  validators,
  setSelectedValidator,
  selectedValidator,
  loading,
}) => {
  return (
    <CardBox className={styles.container}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <SkeletonLoader>
            <Skeleton height={100} width="30%" />
            <Skeleton height={100} width="30%" />
            <Skeleton height={100} width="30%" />
          </SkeletonLoader>
        </div>
      ) : (
        <>
          {validators?.map((validator: Validator) => (
            <ValidatorItem
              key={nanoid(6)}
              name={validator.code}
              status={validator.status}
              createdAt={validator.createdAt}
              updatedAt={validator.updatedAt}
              selectValidator={() => setSelectedValidator(validator)}
              selectedValidator={selectedValidator}
            />
          ))}
        </>
      )}
    </CardBox>
  );
};

export default Validators;
