import clsx from 'clsx';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { SideNavItem } from 'types/SideNavItem';
import './styles.scss';

interface SideNavProps {
  content: Array<SideNavItem>;
  small: Boolean;
}

const MenuGroup: FC<SideNavProps> = ({ content, small }) => {
  const items: Array<React.ReactElement> = [];
  content.forEach((elem, i) => {
    const { icon } = elem;
    if (elem.to !== undefined) {
      elem.hasPermission &&
        items.push(
          <li key={i}>
            <NavLink exact to={elem.to} activeClassName="active">
              {icon}{' '}
              <span
                className={clsx('sidebarLabel', { sidebarLabelSmall: small })}
              >
                {elem.label}
              </span>
            </NavLink>
          </li>,
        );
    }
  });

  return <ul className="nav">{items}</ul>;
};

const SideNav: FC<SideNavProps> = ({ content, small }) => (
  <nav
    key="nav"
    className={clsx('sidebar', { sidebarSmall: small })}
    role="navigation"
  >
    <MenuGroup content={content} small={small} />
  </nav>
);

export default SideNav;
