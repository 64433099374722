import { useReducer, createContext, ReactNode, FC, Children } from 'react';
import reducer, { initialState } from './reducer';
import { AccessManagementUserRoleState } from 'types/AccessManagementUserRoleState';
export const UserRoleContext = createContext<{
  state: AccessManagementUserRoleState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AccessManagementUserRoleProps {
  children: ReactNode;
}
const AccessManagementUserRole: FC<AccessManagementUserRoleProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserRoleContext.Provider value={{ state, dispatch }}>
      {Children.only(children)}
    </UserRoleContext.Provider>
  );
};
export default AccessManagementUserRole;
