import produce from 'immer';
import { AccessManagementUserRoleState } from 'types/AccessManagementUserRoleState';

import { getUserRole, editUserRole } from './actions';

export const initialState: AccessManagementUserRoleState = {
  userRole: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  editUserRole: {
    loading: false,
    loaded: false,
    error: null,
  },
};

export interface AccessManagementUserRoleActions {
  type: string;
  payload: any;
}

const reducer = (
  state: AccessManagementUserRoleState,
  action: AccessManagementUserRoleActions,
) =>
  produce(state, (draftProduce) => {
    const { type, payload } = action;
    const draft = draftProduce;
    switch (type) {
      case getUserRole.REQUEST:
        draft.userRole.loading = true;
        draft.userRole.loaded = false;
        draft.userRole.error = null;
        draft.userRole.list = [];
        break;

      case getUserRole.SUCCESS:
        draft.userRole.loading = false;
        draft.userRole.loaded = true;
        draft.userRole.list = payload;
        break;

      case getUserRole.FAILURE:
        draft.userRole.loading = false;
        draft.userRole.loaded = false;
        draft.userRole.error = payload;
        break;

      case getUserRole.RESET:
        draft.userRole = { ...initialState.userRole };
        break;

      case editUserRole.REQUEST:
        draft.editUserRole.loading = true;
        draft.editUserRole.loaded = false;
        draft.editUserRole.error = null;
        break;

      case editUserRole.SUCCESS:
        draft.editUserRole.loading = false;
        draft.editUserRole.loaded = true;
        break;

      case editUserRole.FAILURE:
        draft.editUserRole.loading = false;
        draft.editUserRole.loaded = false;
        draft.editUserRole.error = payload;
        break;

      case editUserRole.RESET:
        draft.editUserRole = { ...initialState.editUserRole };
        break;

      default:
        return draft;
    }
    return draft;
  });

export default reducer;
