import request from './index';
import { getRoleToken } from '../auth';

import { envConfig } from 'config';
import { RequestConfig } from 'types/RequestConfig';

export async function getPermissions() {
  const roleId = await getRoleToken();
  return request(`${envConfig.API_BASE}/v1/roles/${roleId}`, {
    method: 'GET',
  } as RequestConfig);
}
