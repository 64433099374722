/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { AccessManagementPermissionState } from 'types/AccessManagementPermissionState';

import { createPermission, editPermission, deletePermission } from './actions';

export const initialState: AccessManagementPermissionState = {
  createPermission: {
    loading: false,
    loaded: false,
    error: null,
  },

  editPermission: {
    loading: false,
    loaded: false,
    error: null,
  },

  deletePermission: {
    loading: false,
    loaded: false,
    error: null,
  },
};

export interface AccessManagementPermissionActions {
  type: string;
  payload: any;
}

const reducer = (
  state: AccessManagementPermissionState,
  action?: AccessManagementPermissionActions,
) =>
  produce(state, (draftProduce) => {
    if (!action) return draftProduce;

    const { type, payload } = action;
    const draft = draftProduce;
    switch (type) {
      case createPermission.REQUEST:
        draft.createPermission.loading = true;
        draft.createPermission.loaded = false;
        draft.createPermission.error = null;
        break;

      case createPermission.SUCCESS:
        draft.createPermission.loading = false;
        draft.createPermission.loaded = true;
        break;

      case createPermission.FAILURE:
        draft.createPermission.loading = false;
        draft.createPermission.loaded = false;
        draft.createPermission.error = payload;
        break;

      case createPermission.RESET:
        draft.createPermission = { ...initialState.createPermission };
        break;

      case editPermission.REQUEST:
        draft.editPermission.loading = true;
        draft.editPermission.loaded = false;
        draft.editPermission.error = null;
        break;

      case editPermission.SUCCESS:
        draft.editPermission.loading = false;
        draft.editPermission.loaded = true;
        break;

      case editPermission.FAILURE:
        draft.editPermission.loading = false;
        draft.editPermission.loaded = false;
        draft.editPermission.error = payload;
        break;

      case editPermission.RESET:
        draft.editPermission = { ...initialState.editPermission };
        break;

      case deletePermission.REQUEST:
        draft.deletePermission.loading = true;
        draft.deletePermission.loaded = false;
        draft.deletePermission.error = null;
        break;

      case deletePermission.SUCCESS:
        draft.deletePermission.loading = false;
        draft.deletePermission.loaded = true;
        break;

      case deletePermission.FAILURE:
        draft.deletePermission.loading = false;
        draft.deletePermission.loaded = false;
        draft.deletePermission.error = payload;
        break;

      case deletePermission.RESET:
        draft.deletePermission = { ...initialState.deletePermission };
        break;

      default:
        return draft;
    }
  });

export default reducer;
