/* eslint-disable no-restricted-imports */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useReducer, createContext, FC } from 'react';
import { AccessManagementSectionState } from 'types/AccessManagementSectionState';

import reducer, { initialState } from './reducer';

export const SectionContext = createContext<{
  state: AccessManagementSectionState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AccessManagementSectionProps {
  children?: React.ReactNode;
}

const AccessManagementSection: FC<AccessManagementSectionProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SectionContext.Provider value={{ state, dispatch }}>
      {children}
    </SectionContext.Provider>
  );
};

export default AccessManagementSection;
