import { CSSProperties, FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import startCase from 'lodash/startCase';
import { formatCurrency } from 'utils/formatCurrency';
import { formatDateUTC } from 'utils/dates';
import {
  Analysis,
  DetailMatchTransactionalAnalysis,
  TransactionalAnalysisData,
} from 'types/Transaction';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  TableHead,
  TableColumn,
  Tooltip,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import styles from './styles.module.scss';
import { FaInfoCircle } from 'react-icons/fa';
import DividerName from 'components/DividerName';
import SummaryAlerts from './SummaryAlerts';
import {
  BlockIcon,
  CheckmarkCircleIcon,
  NoInfoIcon,
  WarningIcon,
} from 'components/Icons';
import { TransactionAnalysisStatus } from 'constants/transactionAnalysisStatus';
import { CheckmarkXcircleIcon } from 'components/Icons/CheckmarkXcircleIcon';

type TransactionalResultTxAnalysisProps = {
  selectedTransactionalAnalysisData: TransactionalAnalysisData | null;
  handleOpenModalDetailData: (extraData: any) => void;
};

const TransactionalResultTxAnalysis: FC<TransactionalResultTxAnalysisProps> = ({
  selectedTransactionalAnalysisData,
  handleOpenModalDetailData,
}) => {
  const [controlledSwiper] = useState();
  const matchDetail: DetailMatchTransactionalAnalysis =
    selectedTransactionalAnalysisData?.response
      .matchDetail as DetailMatchTransactionalAnalysis;
  const beneficiaryCalculatedDataHistoric =
    matchDetail?.beneficiaryCalculatedData?.find((data) => {
      return data.type === 'historic';
    });
  const beneficiaryCalculatedDataMonthly =
    matchDetail?.beneficiaryCalculatedData?.find((data) => {
      return data.type === 'monthly';
    });
  const ordenantCalculatedDataHistoric =
    matchDetail?.ordenantCalculatedData?.find((data) => {
      return data.type === 'historic';
    });
  const ordenantCalculatedDataMonthly =
    matchDetail?.ordenantCalculatedData?.find((data) => {
      return data.type === 'monthly';
    });

  const renderIconStatus = (status: string) => {
    if (status?.toLowerCase() !== 'ok') {
      return (
        <Tooltip position="right" message="Analysis execution error.">
          <CheckmarkXcircleIcon />
        </Tooltip>
      );
    }
    return (
      <Tooltip position="right" message="Analysis performed successfully.">
        <CheckmarkCircleIcon />
      </Tooltip>
    );
  };

  const renderIconResult = (status: string, warning: boolean) => {
    const key = nanoid();

    if (status !== TransactionAnalysisStatus.ok) {
      if (
        status === TransactionAnalysisStatus.notFoundProfileData ||
        status === TransactionAnalysisStatus.notFoundProfileThresholdData
      ) {
        return (
          <Tooltip position="left" message="Missing information" key={key}>
            <NoInfoIcon />
          </Tooltip>
        );
      }
      return (
        <Tooltip position="left" message="Analysis not performed" key={key}>
          <BlockIcon />
        </Tooltip>
      );
    }

    if (!warning) {
      return (
        <Tooltip position="left" message="Rule under compliance." key={key}>
          <CheckmarkCircleIcon />
        </Tooltip>
      );
    }

    return (
      <Tooltip position="left" message="Rule breaking" key={key}>
        <WarningIcon />
      </Tooltip>
    );
  };

  const renderWAnalysis = (analysis: Analysis[], title: string) => {
    return (
      <SwiperSlide style={{ paddingInline: '40px' }} key={nanoid()}>
        <Grid row>
          <Margin side="bottom" xs={24}>
            <Typography component="strong" variant="header">
              {title}
            </Typography>
          </Margin>
          <Grid sm={12} xl={12}>
            <Margin side="bottom" xs={48}>
              <Table>
                <TableHead variant="dark">
                  <TableRow>
                    <TableColumn>Type</TableColumn>
                    <TableColumn>Status</TableColumn>
                    <TableColumn>Detail</TableColumn>
                    <TableColumn>Result</TableColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analysis?.map((analysis: Analysis) => (
                    <TableRow key={nanoid()}>
                      <TableCell>
                        <Tooltip
                          position="right"
                          message={analysis.description}
                        >
                          <FaInfoCircle className={styles.infoIcon} />
                        </Tooltip>
                        <Typography>{startCase(analysis.type)}</Typography>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-center">
                          {renderIconStatus(analysis.analysisStatus)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography>{analysis.detail}</Typography>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-center">
                          {renderIconResult(
                            analysis.analysisStatus,
                            analysis.warning,
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Margin>
          </Grid>
        </Grid>
      </SwiperSlide>
    );
  };

  const renderAnalysis = () => {
    if (matchDetail) {
      return (
        <>
          <Margin side="bottom" xs={12}>
            <div className={styles['swipper-container']}>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                navigation
                pagination
                controller={{ control: controlledSwiper }}
                style={
                  {
                    '--swiper-navigation-color': '#3BAFDA',
                    '--swiper-navigation-size': '20px',
                    '--swiper-pagination-color': '#3BAFDA',
                    '--swiper-pagination-bullet-inactive-color': '#999999',
                    '--swiper-pagination-bullet-inactive-opacity': '1',
                    '--swiper-pagination-bullet-size': '10px',
                    '--swiper-pagination-bullet-horizontal-gap': '6px',
                    '--swiper-pagination-top': '80px',
                  } as CSSProperties
                }
              >
                <>
                  {renderWAnalysis(
                    matchDetail.analysisOverride,
                    'Analysis Override',
                  )}
                  {renderWAnalysis(matchDetail.analysisW0, 'Analysis W0')}
                  {renderWAnalysis(matchDetail.analysisW1, 'Analysis W1')}
                  {renderWAnalysis(matchDetail.analysisW2, 'Analysis W2')}
                </>
              </Swiper>
            </div>
          </Margin>
        </>
      );
    }

    return <></>;
  };

  const renderAccordionOrdenant = () => {
    return (
      <Accordion collapsible multiple>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Historic"
            className="d-flex justify-content-between"
          ></AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Amount:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataHistoric?.amount ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataHistoric?.average ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataHistoric?.averageCountMonthly ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Count:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataHistoric?.count ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 1:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataHistoric?.limit1 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 2:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataHistoric?.limit2 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataHistoric?.standardDeviation ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataHistoric?.standardDeviationCountMonthly ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Last evaluated date:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatDateUTC(
                        ordenantCalculatedDataHistoric?.lastEvaluatedDate ??
                          'N/A',
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Monthly"
            className="d-flex justify-content-between"
          ></AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Amount:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.amount ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.average ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.averageCountMonthly ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Count:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataMonthly?.count ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 1:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.limit ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 2:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.limit2 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        ordenantCalculatedDataMonthly?.standardDeviation ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ordenantCalculatedDataMonthly?.standardDeviationCountMonthly ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Last evaluated date:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatDateUTC(
                        ordenantCalculatedDataMonthly?.lastEvaluatedDate ??
                          'N/A',
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const renderAccordionBeneficiary = () => {
    return (
      <Accordion collapsible multiple>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Historic"
            className="d-flex justify-content-between"
          ></AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Amount:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataHistoric?.amount ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataHistoric?.average ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataHistoric?.averageCountMonthly ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Count:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataHistoric?.count ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 1:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataHistoric?.limit1 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 2:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataHistoric?.limit2 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataHistoric?.standardDeviation ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataHistoric?.standardDeviationCountMonthly ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Last evaluated date:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatDateUTC(
                        beneficiaryCalculatedDataHistoric?.lastEvaluatedDate ??
                          'N/A',
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Monthly"
            className="d-flex justify-content-between"
          ></AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Amount:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.amount ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.average ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Average count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.averageCountMonthly ??
                          0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Count:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataMonthly?.count ?? 0}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 1:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.limit ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Limit 2:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.limit2 ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(
                        beneficiaryCalculatedDataMonthly?.standardDeviation ??
                          0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Standard deviation count monthly:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {beneficiaryCalculatedDataMonthly?.standardDeviationCountMonthly ??
                        0}
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow key={nanoid()}>
                  <TableCell>
                    <Typography component="strong" variant="subHeader">
                      Last evaluated date:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatDateUTC(
                        beneficiaryCalculatedDataMonthly?.lastEvaluatedDate ??
                          'N/A',
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction result
          </Typography>
          <Button color="link" onClick={handleOpenModalDetailData}>
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <div>
          <SummaryAlerts matchDetail={matchDetail} />
        </div>
        <div className={styles.divider} />
        {renderAnalysis()}
        <DividerName title="Ordenant calculated data">
          {renderAccordionOrdenant()}
        </DividerName>
        <DividerName title="Beneficiary calculated data">
          {renderAccordionBeneficiary()}
        </DividerName>
      </Margin>
    </>
  );
};

export default TransactionalResultTxAnalysis;
