import { FC, ReactNode } from 'react';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableColumn,
} from '@gbm/queen-ui-guidelines';
import startCase from 'lodash/startCase';
import { isEmpty, isObject, isNumber, isNull, isDate } from 'lodash';
import { nanoid } from 'nanoid';
import styles from './styles.module.scss';
import { formatNumber } from 'utils/formatCurrency';
import { formatDateTimeTZUser, formatDateUTC } from 'utils/dates';

type ExtraInfoProps = {
  extraInfo: any;
};
const ExtraInfo: FC<ExtraInfoProps> = ({ extraInfo }) => {
  const showValue = (value: any) => {
    if (isNull(value)) {
      return '-';
    }

    if (isNumber(value)) {
      return formatNumber(value);
    }

    if (!isNaN(Date.parse(value))) {
      return formatDateTimeTZUser(value.toString());
    }

    return JSON.stringify(value);
  };
  const renderRowsInTable = (property: string, value: any) => {
    return (
      <Margin side="bottom" xs={24} key={nanoid()}>
        {property !== 'base' && (
          <Margin side="bottom" xs={12}>
            <Typography weight="bold">{startCase(property)}</Typography>
          </Margin>
        )}

        <Table>
          {/* <TableHead>
              <TableRow>
                <TableColumn className={styles.tableCellWrapper}>
                  Data
                </TableColumn>
                <TableColumn className={styles.tableCellWrapper}>
                  Value
                </TableColumn>
              </TableRow>
            </TableHead> */}
          <TableBody>
            {Object.entries(value).map((info) => {
              const property = info[0];
              const value = info[1];

              return (
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      {startCase(property)}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>{showValue(value)}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Margin>
    );
  };
  const renderExtraInfo = (): ReactNode => {
    let detailDataObject = { base: {} };
    const extraInfoAsArray = Object.entries(extraInfo);
    extraInfoAsArray.forEach((info) => {
      if (isObject(info[1])) {
        detailDataObject = { ...detailDataObject, [info[0]]: info[1] };
      } else {
        detailDataObject = {
          ...detailDataObject,
          base: { ...detailDataObject['base'], [info[0]]: info[1] },
        };
      }
    });
    return (
      <>
        {Object.entries(detailDataObject).map((info) => {
          const property = info[0];
          const value = info[1];
          if (!isEmpty(value)) {
            return renderRowsInTable(property, value);
          } else return <></>;
        })}
      </>
    );
  };
  return (
    <div className={styles.wrapperExtraInfo}>
      {extraInfo && renderExtraInfo()}
    </div>
  );
};

export default ExtraInfo;
