import { lazy, Suspense } from 'react';
import { Spinner } from '@gbm/queen-ui-guidelines';
import Backdrop from 'components/Backdrop';

const renderBackdrop = () => (
  <Backdrop variant="transparent">
    <Spinner size="sm" color="info" />
  </Backdrop>
);

const loadable = (
  importFunc: any,
  { fallback = null } = {
    fallback: renderBackdrop(),
  },
) => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
