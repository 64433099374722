export const formatCurrency = (number?: number): string => {
  if (!number) return '';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export const formatNumber = (number?: number): string => {
  if (!number) return '';

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
  }).format(number);
};
