/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { LOCAL_STORAGE } from 'constants/localStorage';
import {
  getTokenRequest,
  getRoleToken,
  getPermissionsList,
  refreshTokenRequest,
} from 'utils/auth';
import { getPermissions } from 'utils/request/roles';

export const usePrivateRoute = () => {
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const [, code] = location.search.split('?code=');

  const history = useHistory();

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  const getToken = async () => {
    if (location.search.includes('code')) {
      try {
        const { idToken, refreshToken } = await getTokenRequest({ code });

        localStorage.setItem(LOCAL_STORAGE.TOKEN, idToken);
        localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);

        const role = await getRoleToken();
        localStorage.setItem(LOCAL_STORAGE.ROLE_ID, role);

        history.replace(`/`);
      } catch (error) {
        setIsLoggedOut(true);
      }
    }
  };

  const loadPermissions = async () => {
    setLoading(true);

    try {
      const { permissions: permissionLists } = await getPermissions();
      const permissionList = getPermissionsList(permissionLists);
      setPermissions(permissionList);
    } catch (error) {
      setIsLoggedOut(true);
    } finally {
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    const { idToken } = await refreshTokenRequest(
      localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN) || '',
    );
    localStorage.setItem(LOCAL_STORAGE.TOKEN, idToken);
  };

  useEffect(() => {
    getToken();
  }, [history, location.search, code]);

  useEffect(() => {
    if (token && !isLoggedOut) {
      loadPermissions();
    }
  }, [code, isLoggedOut, token]);

  return {
    token,
    permissions,
    state: { loading, isLoggedOut },
    actions: { refreshToken, setIsLoggedOut, searchLocation: location.search },
  };
};
