import { Children, FunctionComponent, ReactNode } from 'react';
import { FeatureContext } from './FeatureContext';

type FeatureProviderProps = {
  features?: Object;
  children: ReactNode;
};

const FeatureProvider: FunctionComponent<FeatureProviderProps> = ({
  features,
  children,
}) => {
  if (!features || features === null || typeof features !== 'object') {
    throw new TypeError('The features prop must be an object or an array.');
  }

  return (
    <FeatureContext.Provider value={features as any}>
      {Children.only(children)}
    </FeatureContext.Provider>
  );
};

export default FeatureProvider;
