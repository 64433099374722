import PlaceholdersTable from 'components/PlaceholdersTable';
import {
  Grid,
  Table,
  TableBody,
  SkeletonLoader,
  Skeleton,
} from '@gbm/queen-ui-guidelines';
import styles from './styles.module.scss';
const TransactionSkeleton = () => {
  return (
    <Grid row justify="center">
      <Grid md={6}>
        <Table>
          <TableBody>
            <PlaceholdersTable
              placeholderCols={2}
              placeholderRows={3}
              width="100%"
            />
          </TableBody>
        </Table>
        <SkeletonLoader>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </SkeletonLoader>
      </Grid>
      <Grid md={6}>
        <div className={styles.loaderContainer}>
          <SkeletonLoader>
            <Skeleton height={45} width="30%" />
            <Skeleton height={45} width="30%" />
            <Skeleton height={45} width="30%" />
          </SkeletonLoader>
        </div>

        <Table>
          <TableBody>
            <PlaceholdersTable
              placeholderCols={4}
              placeholderRows={3}
              width="100%"
            />
          </TableBody>
        </Table>
        <SkeletonLoader>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </SkeletonLoader>
      </Grid>
    </Grid>
  );
};

export default TransactionSkeleton;
