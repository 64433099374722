import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/routines';

import {
  GET_SECTIONS,
  CREATE_SECTION,
  EDIT_SECTION,
  DELETE_SECTION,
} from './constants';

export const getSections = createRoutine(GET_SECTIONS);
export const createSection = extendRoutine(
  createRoutine(CREATE_SECTION),
  'RESET',
);
export const editSection = extendRoutine(createRoutine(EDIT_SECTION), 'RESET');
export const deleteSection = extendRoutine(
  createRoutine(DELETE_SECTION),
  'RESET',
);
