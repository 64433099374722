import { CognitoConfig } from 'types/CognitoConfig';
import { Env, Environments } from 'types/Env';

const configDefaultCognito = {
  token: '/oauth2/token',
  authorize: '/oauth2/authorize?',
  logout: '/logout?',
  grantType: 'authorization_code',
  grantTypeRefreshToken: 'refresh_token',
  login: '/login?',
};

const environments: Environments = {
  LOCAL: {
    apiBase: 'https://van.aml-fd.dev-gbmapi.com/api',
    apiBasePatron: 'https://backoffice.auth.dev-gbmapi.com',
    rolloutKey: '6079adf812147765a02a3ab1',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl:
        'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'http://localhost:3000/',
      authRedirectLoginUrl: 'http://localhost:3000/',
      authClientId: '12ah1v5raliteogklr0p5co3oc',
    },
  },
  dev: {
    apiBase: 'https://van.aml-fd.dev-gbmapi.com/api',
    apiBasePatron: 'https://backoffice.auth.dev-gbmapi.com',
    rolloutKey: '6079adf812147765a02a3ab1',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl:
        'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https://van.aml-fd.dev-gbmapi.com/',
      authRedirectLoginUrl: 'https://van.aml-fd.dev-gbmapi.com/',
      authClientId: '12ah1v5raliteogklr0p5co3oc',
    },
  },
  stg: {
    apiBase: 'https://van.aml-fd.stg-gbmapi.com/api',
    apiBasePatron: 'https://backoffice.auth.stg-gbmapi.com',
    rolloutKey: '6079ae18e954a7360cb1878f',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl:
        'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https://van.aml-fd.stg-gbmapi.com/',
      authRedirectLoginUrl: 'https://van.aml-fd.stg-gbmapi.com/',
      authClientId: '2t8rqomhqlu1367g3p4ouof3rt',
    },
  },
  prd: {
    apiBase: 'https://van.aml-fd.gbmapi.com/api',
    apiBasePatron: 'https://backoffice.auth.gbmapi.com',
    rolloutKey: '6079ad22a3c35327f3e07d98',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl:
        'https://patron-backoffice-prd-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https://van.aml-fd.gbmapi.com/',
      authRedirectLoginUrl: 'https://van.aml-fd.gbmapi.com/',
      authClientId: '7g2ob9doq93tn1is4udrmves6v',
    },
  },
};

const createFrontendConfig = (appEnv = process.env.REACT_APP_ENV) => {
  const env: Env = {
    NODE_ENV: process.env.NODE_ENV || 'development',
    APP_ENV: getEnv(appEnv),
    API_BASE: '',
    CONFIG_DEFAULT_COGNITO: {} as CognitoConfig,
    ROLLOUT_KEY: '',
    API_BASE_PATRON: '',
  };

  const selectedEnvironment = environments[env.APP_ENV as keyof Environments];

  env.API_BASE = selectedEnvironment.apiBase;
  env.CONFIG_DEFAULT_COGNITO = selectedEnvironment.configDefaultCognito;
  env.ROLLOUT_KEY = selectedEnvironment.rolloutKey;
  env.API_BASE_PATRON = selectedEnvironment.apiBasePatron;
  return env;
};

const getEnv = (appEnv: string | undefined) => {
  let environment = appEnv || 'LOCAL';

  if (Object.keys(environments).indexOf(environment) < 0) {
    environment = 'LOCAL';
  }

  return environment;
};

export default createFrontendConfig;
