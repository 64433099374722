import { DropdownOptions } from 'types/DropdownOptions';

export const options: DropdownOptions[] = [
  {
    label: 'Accepted',
    displayName: 'Accepted',
    name: 'ACCEPTED',
    value: 'ACCEPTED',
    id: 'ACCEPTED',
    code: 'ACCEPTED',
  },
  {
    label: 'Rejected',
    displayName: 'Rejected',
    name: 'REJECTED',
    value: 'REJECTED',
    id: 'REJECTED',
    code: 'REJECTED',
  },
];
