import { FC } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface BackdropProps {
  variant: string;
  [others: string]: any;
}
const Backdrop: FC<BackdropProps> = ({ variant, ...others }) => (
  <div
    className={clsx(styles.root, {
      [styles[variant]]: variant === 'transparent',
    })}
    {...others}
  />
);

export default Backdrop;
