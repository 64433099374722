import { CSSProperties, FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import PlaceholdersTable from 'components/PlaceholdersTable';
import { DetailMatchListMatching, ListMatchingData } from 'types/Transaction';
import styles from './styles.module.scss';
import ModalComplementaryData from '../ModalComplementaryData';
import { formatDateUTC } from 'utils/dates';
import { getStatusToDisplay } from 'utils/transactionsUtils';

type TransactionResultListMatchingProps = {
  selectedListMatchingData: ListMatchingData | undefined;
  handleOpenModalDetailData: (extraData: any) => void;
};

const TransactionResultListMatching: FC<TransactionResultListMatchingProps> = ({
  selectedListMatchingData,
  handleOpenModalDetailData,
}) => {
  const [controlledSwiper] = useState();
  const [isOpenModalDetailData, setIsOpenModalDetailData] =
    useState<boolean>(false);
  const [matchDetailSelected, setMatchDetailSelected] =
    useState<DetailMatchListMatching>();
  const matchDetails: DetailMatchListMatching[] = selectedListMatchingData
    ?.validator.matchDetail as DetailMatchListMatching[];
  const renderMatchDetail = () => {
    if (matchDetails) {
      if (matchDetails?.length > 0) {
        return (
          <>
            <Margin side="bottom" xs={12}>
              <div className={styles['swipper-container']}>
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={50}
                  navigation
                  pagination
                  controller={{ control: controlledSwiper }}
                  style={
                    {
                      '--swiper-navigation-color': '#3BAFDA',
                      '--swiper-navigation-size': '20px',
                      '--swiper-pagination-color': '#3BAFDA',
                      '--swiper-pagination-bullet-inactive-color': '#999999',
                      '--swiper-pagination-bullet-inactive-opacity': '1',
                      '--swiper-pagination-bullet-size': '10px',
                      '--swiper-pagination-bullet-horizontal-gap': '6px',
                      '--swiper-pagination-top': '80px',
                    } as CSSProperties
                  }
                >
                  {matchDetails?.map((detail) => {
                    const matchDetail: DetailMatchListMatching =
                      detail as DetailMatchListMatching;
                    return (
                      <SwiperSlide
                        style={{ paddingInline: '40px' }}
                        key={nanoid()}
                      >
                        <Grid row>
                          <Grid sm={12} xl={12}>
                            <Margin side="bottom" xs={48}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Category:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {matchDetail.category}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Subcategory:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {matchDetail.subcategory}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Level:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>{matchDetail?.level}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        component="strong"
                                        variant="subHeader"
                                      >
                                        Full name:
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {matchDetail?.fullName}
                                    </TableCell>
                                  </TableRow>
                                  {(matchDetail?.dateOfBirth ||
                                    matchDetail?.alternativeDateOfBirth) && (
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          component="strong"
                                          variant="subHeader"
                                        >
                                          Date birth
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {formatDateUTC(
                                          matchDetail.dateOfBirth ||
                                            matchDetail.alternativeDateOfBirth,
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                              <Button
                                className="w-100"
                                size="sm"
                                onClick={() => {
                                  setIsOpenModalDetailData(true);
                                  setMatchDetailSelected(matchDetail);
                                }}
                              >
                                See detail data
                              </Button>
                            </Margin>
                          </Grid>
                        </Grid>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </Margin>
          </>
        );
      }
    }

    return <></>;
  };
  const renderContent = () => {
    return (
      <>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {getStatusToDisplay(selectedListMatchingData?.validator.match)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match action:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {getStatusToDisplay(
                selectedListMatchingData?.validator.matchAction,
              )}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match level:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {selectedListMatchingData?.validator.matchLevel}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Match detail:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{matchDetails?.length}</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction result
          </Typography>
          <Button
            color="link"
            disabled={true}
            onClick={() =>
              handleOpenModalDetailData(selectedListMatchingData?.details)
            }
          >
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <Table>
          <TableBody>{renderContent()}</TableBody>
        </Table>
        {renderMatchDetail()}
        {isOpenModalDetailData && (
          <ModalComplementaryData
            onClose={() => setIsOpenModalDetailData(false)}
            isOpen={isOpenModalDetailData}
            matchDetailSelected={matchDetailSelected}
          />
        )}
      </Margin>
    </>
  );
};

export default TransactionResultListMatching;
