import { FC } from 'react';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import { CountryData } from 'types/Transaction';
import { isEmpty, startCase } from 'lodash';

type TransactionInformationProps = {
  selectedCountryData: CountryData | undefined;
  handleOpenModalDetailData: (extraData: any) => void;
};
const TransactionInformationCountries: FC<TransactionInformationProps> = ({
  selectedCountryData,
  handleOpenModalDetailData,
}) => {
  const renderContent = () => {
    return (
      <>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Country:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {startCase(selectedCountryData?.name ?? '')}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Type:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {startCase(selectedCountryData?.type ?? '')}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Entity type:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{selectedCountryData?.entityType ?? 'N/A'}</Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Entity id:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{selectedCountryData?.entityId ?? 'N/A'}</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction information
          </Typography>
          <Button
            color="link"
            disabled={isEmpty(selectedCountryData?.details)}
            onClick={() =>
              handleOpenModalDetailData(selectedCountryData?.details)
            }
          >
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <Table>
          <TableBody>{renderContent()}</TableBody>
        </Table>
      </Margin>
    </>
  );
};

export default TransactionInformationCountries;
