import { FC, ReactNode } from 'react';
import useFeature from './hooks/useFeature';

interface FeatureProps {
  name: string;
  children: ReactNode | Function;
}
const Feature: FC<FeatureProps> = ({ name, children }) => {
  const hasFeature = useFeature(name);

  if (typeof children === 'function') return children(hasFeature);

  return hasFeature ? children : null;
};

export default Feature;
