/* eslint-disable no-restricted-imports */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useReducer, createContext, FC } from 'react';
import { AccessManagementRoleState } from 'types/AccessManagementRoleState';

import reducer, { initialState } from './reducer';

export const RoleContext = createContext<{
  state: AccessManagementRoleState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AccessManagementRoleProps {
  children?: React.ReactNode;
}

const AccessManagementRole: FC<AccessManagementRoleProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RoleContext.Provider value={{ state, dispatch }}>
      {children}
    </RoleContext.Provider>
  );
};

export default AccessManagementRole;
