import { DisplayMessage } from '@gbm/queen-ui-guidelines';
import { TransactionStatus } from 'constants/transactionStatus';
import ValidationsToSave from 'pages/TransactionDetail/ValidationsToSave';
import { useSelectorsTransactions } from 'providers/Transactions/useSelectors';
import { FC, useEffect, useState } from 'react';
import { SavedValidations } from 'types/SavedValidations';
import {
  CountryData,
  ListMatchingData,
  ManualReviewDetail,
  TransactionalAnalysisData,
  ValidatorManualReview,
} from 'types/Transaction';
import { TransactionsToSave } from 'types/TransactionsState';
import DetailCanceledTransaction from './components/DetailCanceledTransaction';
import SavedManualReviews from './components/SavedManualReviews';
import SideValidationsToSave from './components/SideValidationsToSave';

interface SidebarValidationsBodyProps {
  dataValidationsToShow: TransactionsToSave;
  countriesValidatorData: CountryData[] | null;
  listMatchingValidatorData: ListMatchingData[] | null;
  transactionalAnalysisValidatorData: TransactionalAnalysisData | null;
  loading: boolean;
  handleUpdateVerification: (validator: ValidatorManualReview) => void;
  checkValidationsChecked: () => boolean;
  setIsOpenConfirmReviewDialog: (state: boolean) => void;
}

const SidebarValidationsBody: FC<SidebarValidationsBodyProps> = ({
  dataValidationsToShow,
  countriesValidatorData,
  listMatchingValidatorData,
  transactionalAnalysisValidatorData,
  loading,
  handleUpdateVerification,
  checkValidationsChecked,
  setIsOpenConfirmReviewDialog,
}) => {
  const { transactionState } = useSelectorsTransactions();

  const [listOfSavedManualReviews, setListOfSavedManualReviews] =
    useState<SavedValidations>({
      listMatching: [],
      countries: [],
      txn: null,
    });

  useEffect(() => {
    if (countriesValidatorData) {
      let savedCountryData: ManualReviewDetail[] = [];
      countriesValidatorData.forEach((countryData: CountryData) => {
        if (countryData.manualReview) {
          savedCountryData = [...savedCountryData, countryData.manualReview];
        }
      });

      setListOfSavedManualReviews({
        ...listOfSavedManualReviews,
        countries: [...savedCountryData],
      });
    }
    if (listMatchingValidatorData) {
      let savedListMatchingData: ManualReviewDetail[] = [];

      listMatchingValidatorData.forEach(
        (listMatchingData: ListMatchingData) => {
          if (listMatchingData.manualReview) {
            savedListMatchingData = [
              ...savedListMatchingData,
              listMatchingData.manualReview,
            ];
          }
        },
      );

      setListOfSavedManualReviews({
        ...listOfSavedManualReviews,
        listMatching: [...savedListMatchingData],
      });
    }

    if (transactionalAnalysisValidatorData?.manualReview) {
      setListOfSavedManualReviews({
        ...listOfSavedManualReviews,
        txn: { ...transactionalAnalysisValidatorData.manualReview },
      });
    }
  }, [
    countriesValidatorData,
    listMatchingValidatorData,
    transactionalAnalysisValidatorData,
  ]);

  if (
    dataValidationsToShow.countries.length > 0 ||
    dataValidationsToShow.listmatching.length > 0 ||
    dataValidationsToShow.txn.length > 0
  ) {
    return (
      <SideValidationsToSave
        dataValidationsToShow={dataValidationsToShow}
        loading={loading}
        handleUpdateVerification={handleUpdateVerification}
        checkValidationsChecked={checkValidationsChecked}
        setIsOpenConfirmReviewDialog={setIsOpenConfirmReviewDialog}
      />
    );
  }

  if (
    transactionState.item?.status === TransactionStatus.canceled &&
    transactionState.item?.statusReview !== null
  ) {
    return (
      <DetailCanceledTransaction
        loading={loading}
        manualReviewDetail={transactionState.item?.statusReview?.details}
      />
    );
  }
  if (
    listOfSavedManualReviews.countries.length > 0 ||
    listOfSavedManualReviews.listMatching.length > 0 ||
    Object.keys(listOfSavedManualReviews.txn ?? {}).length > 0
  ) {
    return (
      <SavedManualReviews
        loading={loading}
        listOfSavedManualReviews={listOfSavedManualReviews}
      />
    );
  }

  return (
    <DisplayMessage
      message="Complete the manual review data"
      variant={'info'}
    />
  );
};

export default SidebarValidationsBody;
