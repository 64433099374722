import { FC } from 'react';
import { Typography, Badge, Tooltip } from '@gbm/queen-ui-guidelines';
import { FaInfoCircle } from 'react-icons/fa';

import {
  getCodeToDisplay,
  getColorForBadge,
  getStatusToDisplay,
} from 'utils/transactionsUtils';

import styles from './styles.module.scss';
import { formatDateTimeTZUser } from 'utils/dates';
import { Validator } from 'types/Transaction';
import clsx from 'clsx';

interface ValidatorProps {
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  selectedValidator: Validator | null;
  selectValidator: () => void;
}

const ValidatorItem: FC<ValidatorProps> = ({
  name,
  status,
  createdAt,
  updatedAt,
  selectedValidator,
  selectValidator,
}) => (
  <div
    className={clsx(styles.validatorContainer, {
      [styles.selectedValidatorContainer]: selectedValidator?.code === name,
    })}
    onClick={selectValidator}
  >
    <div className={styles.headerContainer}>
      <Badge
        className={styles.validatorBadge}
        color={getColorForBadge(status)}
        variant="pill"
      >
        {getStatusToDisplay(status)}
      </Badge>
      <Tooltip
        message={`Created at: ${formatDateTimeTZUser(
          createdAt ?? '',
        )} Updated at: ${formatDateTimeTZUser(updatedAt ?? '')}`}
      >
        <FaInfoCircle className={styles.infoIcon} />
      </Tooltip>
    </div>
    <div className={styles.validatorInfo}>
      <Typography className={styles.validatorTitle} variant="headerTitle">
        {getCodeToDisplay(name)}
      </Typography>
      <div></div>
    </div>
  </div>
);

export default ValidatorItem;
