/* eslint-disable react/jsx-no-constructed-context-values */
// eslint-disable-next-line no-restricted-imports
import React, { useReducer, createContext, FC } from 'react';
import { AccessManagementPermissionState } from 'types/AccessManagementPermissionState';

import reducer, { initialState } from './reducer';

export const PermissionContext = createContext<{
  state: AccessManagementPermissionState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AccessManagementPermissionProps {
  children?: React.ReactNode;
}

const AccessManagementPermission: FC<AccessManagementPermissionProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PermissionContext.Provider value={{ state, dispatch }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default AccessManagementPermission;
