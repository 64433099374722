import { FC } from 'react';
import { ListMatchingData } from 'types/Transaction';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import { formatDateUTC } from 'utils/dates';
import { isEmpty } from 'lodash';

type TransactionInformationListMatchingProps = {
  selectedListMatchingData: ListMatchingData | undefined;
  handleOpenModalDetailData: (extraData: any) => void;
};

const TransactionInformationListMatching: FC<
  TransactionInformationListMatchingProps
> = ({ selectedListMatchingData, handleOpenModalDetailData }) => {
  const renderContent = () => {
    return (
      <>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Name:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{selectedListMatchingData?.firstName}</Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Last name:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {selectedListMatchingData?.lastName ?? 'N/A'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Birth day:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {formatDateUTC(selectedListMatchingData?.dateOfBirth ?? '')}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Party type:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {selectedListMatchingData?.partyType ?? 'N/A'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Is legal entity?:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {selectedListMatchingData?.isLegalEntity ? 'Yes' : 'No'}
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction information
          </Typography>
          <Button
            color="link"
            disabled={isEmpty(selectedListMatchingData?.details)}
            onClick={() =>
              handleOpenModalDetailData(selectedListMatchingData?.details)
            }
          >
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <Table>
          <TableBody>{renderContent()}</TableBody>
        </Table>
      </Margin>
    </>
  );
};

export default TransactionInformationListMatching;
