import { ForwardedRef, forwardRef } from 'react';
import clsx from 'clsx';

import camelCase from 'lodash/camelCase';

import { Label, Icon, Dropdown, DropdownItem } from '@gbm/queen-ui-guidelines';
import styles from './styles.module.scss';
import { DropdownOptions } from 'types/DropdownOptions';

export type DropdownControllerProps = {
  options?: DropdownOptions[];
  onChange?: Function;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  size?: string;
  name?: string;
};

const DropdownController = forwardRef(function DropdownController(
  props: DropdownControllerProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    options,
    onChange,
    label,
    required = true,
    placeholder,
    disabled,
    id,
    className,
    size,
    name,
  } = props;
  return (
    <div className={styles.selectField}>
      {label && <Label required={required}>{label}</Label>}

      <Dropdown
        data-testid={id}
        id={id}
        disabled={disabled}
        color="light"
        label={placeholder}
        iconButton={<Icon className="mdi mdi-chevron-down" />}
        className={clsx(styles.dropdown, className)}
        variant="button"
        size={size}
        ref={ref}
        name={name}
      >
        {options?.map((item) => {
          const idOption = item.value || item.name || item.id || item.code;
          return (
            <DropdownItem
              data-testid={camelCase(idOption)}
              id={camelCase(idOption)}
              onClick={() => onChange && onChange(item)}
              key={idOption}
            >
              {item.label || item.displayName || item.name}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </div>
  );
});

export default DropdownController;
