import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Margin,
  Textarea,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@gbm/queen-ui-guidelines';

type FormValues = {
  /* TODO: Refactor this to not send all the properties and only send the value property */
  dropdownStatus: {
    value: string;
    displayName: string;
    code: string;
    id: string;
    label: string;
    name: string;
  };
  comment: string;
  supplementaryComment: string;
};

export interface ModalConfirmCancelationProps {
  onClose: Function;
  onSubmit: Function;
  isOpen: boolean;
}

const ModalConfirmCancelation: FC<ModalConfirmCancelationProps> = ({
  onClose,
  onSubmit,
  isOpen,
}) => {
  const {
    formState: { isDirty, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  return (
    <Modal
      id="modalDetailCoincidences"
      data-testid="modalDetailCoincidences"
      isOpen={isOpen}
      size="md"
      scrollable
      centered
    >
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit(values);
          reset();
        })}
      >
        <ModalHeader onClose={onClose}>Cancel transaction</ModalHeader>
        <ModalBody>
          <Margin side="bottom" xs={12}>
            <Textarea
              id="manualReviewComment"
              data-testid="manualReviewComment"
              label="Comment"
              rows={3}
              placeholder="Add a comment"
              {...register('comment', {
                required: 'Comment is required',
              })}
            />
          </Margin>
          <Margin side="bottom" xs={24}>
            <Textarea
              id="manualReviewSupplementaryComment"
              data-testid="manualReviewSupplementaryComment"
              label="Supplementary comment"
              rows={3}
              placeholder="Add a supplementary comment"
              {...register('supplementaryComment')}
            />
          </Margin>
        </ModalBody>
        <ModalFooter>
          <Button
            id="btnCloseModalVerification"
            data-testid="btnCloseModalVerification"
            type="button"
            color="light"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            Close
          </Button>
          <Button
            id="btnSubmitAddVerification"
            data-testid="btnSubmitAddVerification"
            type="submit"
            disabled={!(isValid && isDirty)}
          >
            Cancel transaction
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalConfirmCancelation;
