import decode, { JwtPayload } from 'jwt-decode';
import camelCase from 'lodash/camelCase';

import { envConfig } from 'config';
import { LOCAL_STORAGE } from 'constants/localStorage';
import { LOGOUT_MINUTES } from 'constants/auth';
import { PermissionList } from 'types/PermissionList';
import { HeadersConfig } from 'types/RequestConfig';

import request from './request';
import { getUserRequest } from './request/users';

const {
  authClientId,
  authRedirectLoginUrl,
  authRedirectLogoutUrl,
  authUrl,
  grantType,
  grantTypeRefreshToken,
  login,
  logout,
  token: tokenConfig,
} = envConfig.CONFIG_DEFAULT_COGNITO;

export function getTokenRequest({ code }: { code: string }) {
  return request(
    `${authUrl}${tokenConfig}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      } as HeadersConfig,
      body: {
        grant_type: grantType,
        client_id: authClientId,
        redirect_uri: authRedirectLoginUrl,
        code,
      },
    },
    false,
  );
}

export function refreshTokenRequest(refreshToken: string) {
  return request(
    `${authUrl}${tokenConfig}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      } as HeadersConfig,
      body: {
        grant_type: grantTypeRefreshToken,
        client_id: authClientId,
        refresh_token: refreshToken,
      },
    },
    false,
  );
}

export function getTokenUsername() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const decoded: any = decode(token || '');

  return decoded.given_name || decoded['cognito:username'] || null;
}

export function getCognitoUsername() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const decodeMapping: any = decode(token || '');

  const cognitoUser = decodeMapping['cognito:username'];
  return cognitoUser?.replace('GBM_', '');
}

export function getTokenExpirationDate(token: string) {
  if (!token) {
    return null;
  }

  const date = new Date(0);
  const decodedToken: JwtPayload = decode(token);
  date.setUTCSeconds(decodedToken.exp!);
  return date;
}

export async function getRoleToken() {
  const roleId = localStorage.getItem(LOCAL_STORAGE.ROLE_ID);
  if (roleId) {
    return roleId;
  }
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const decodedToken: JwtPayload = decode(token || '');
  const { sub } = decodedToken;
  const { role } = await getUserRequest(sub!);
  return role || null;
}

export function isTokenExpired(token: string) {
  const expirationDate = getTokenExpirationDate(token);
  const tokenExpired =
    expirationDate === null
      ? false
      : !(expirationDate.valueOf() > new Date().valueOf());

  return tokenExpired;
}

export function isSessionExpired() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  return token && isTokenExpired(token);
}

export function onIdleTimeout() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  if (!token) {
    return false;
  }

  const decodeMapping: JwtPayload = decode(token);
  const seconds = 1000;
  const minutes = 60;
  const time =
    (+new Date(decodeMapping.exp! * seconds) - +new Date()) / seconds / minutes;

  return parseInt(time.toString(), 10) <= LOGOUT_MINUTES;
}

export function setActive() {
  const now = Date.now();
  localStorage.setItem(LOCAL_STORAGE.LAST_TIME_ACTIVE, now.toString());
}

export function shouldWarn() {
  const key = localStorage.getItem(LOCAL_STORAGE.LAST_TIME_ACTIVE) || 0;
  return parseInt(key.toString(), 10) > 0;
}

export function redirectToLogin() {
  const redirectURL = `${authUrl}${login}client_id=${authClientId}&response_type=code&scope=openid+aws.cognito.signin.user.admin+profile&redirect_uri=${authRedirectLoginUrl}`;
  return window.location.replace(redirectURL);
}

export function signOut() {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.ROLE_ID);
  return window.location.replace(
    `${authUrl}${logout}client_id=${authClientId}&logout_uri=${authRedirectLogoutUrl}`,
  );
}

export const getPermissionsList = (userPermissions: PermissionList) => {
  const getValuesCamelCase = (values: string[]) =>
    values.map((element) => camelCase(element));

  const emptyPermissions: string[] = [];

  const listPermissions: string[] = Object.entries(userPermissions).reduce(
    (permissions, [permissionKey, permissionValue]) => {
      const camelCasePermissions = getValuesCamelCase(permissionValue);
      permissions.push(...camelCasePermissions, permissionKey);
      return permissions;
    },
    emptyPermissions,
  );

  return listPermissions;
};
