import DividerName from 'components/DividerName';
import { nanoid } from 'nanoid';
import ValidationToShowCard from 'pages/TransactionDetailV2/ValidationToShowCard';
import { FC } from 'react';
import { ManualReviewDetail } from 'types/Transaction';

type DetailCanceledTransactionProps = {
  loading: boolean;
  manualReviewDetail: ManualReviewDetail | undefined;
};
const DetailCanceledTransaction: FC<DetailCanceledTransactionProps> = ({
  loading,
  manualReviewDetail,
}) => {
  return (
    <DividerName title="Detail Review">
      <div key={nanoid()} className="w-100">
        <ValidationToShowCard
          loading={loading}
          status={manualReviewDetail?.status ?? ''}
          comments={manualReviewDetail?.comments ?? ''}
          complementaryComments={
            manualReviewDetail?.complementaryComments ?? ''
          }
        />
      </div>
    </DividerName>
  );
};

export default DetailCanceledTransaction;
