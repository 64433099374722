import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import SignIn from 'pages/SignIn/Loadable';
import SignOut from 'pages/SignOut/Loadable';
import AccessManagement from 'pages/AccessManagement/Loadable';
import Transactions from 'pages/Transactions/Loadable';
import Configurations from 'pages/Configurations/Loadable';
import ConfigurationValidators from 'pages/ConfigurationValidators/Loadable';
// import TransactionDetail from 'pages/TransactionDetail/Loadable';
import TransactionDetailV2 from 'pages/TransactionDetailV2';
import { ROUTES } from 'constants/routes';
import PrivateRoute from 'routes/PrivateRoute';

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
      <PrivateRoute exact path={ROUTES.SIGN_OUT} component={SignOut} />
      <PrivateRoute exact path={ROUTES.ALERTS} component={Transactions} />
      <PrivateRoute
        exact
        path={ROUTES.CONFIGURATIONS}
        component={Configurations}
      />
      <PrivateRoute
        exact
        path={ROUTES.CONFIGURATION_VALIDATORS}
        component={ConfigurationValidators}
      />
      <PrivateRoute
        path={ROUTES.TRANSACTION_DETAIL}
        component={TransactionDetailV2}
      />
      <PrivateRoute
        exact
        path={ROUTES.ACCESS_MANAGEMENT}
        component={AccessManagement}
      />
    </Switch>
  </Router>
);

export default AppRouter;
