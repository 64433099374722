import Header from './Header';
import Validators from './Validators';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatcherTransactions } from 'providers/Transactions/useDispatcher';
import { useSelectorsTransactions } from 'providers/Transactions/useSelectors';
import SideView from './SideView';
import clsx from 'clsx';
import {
  CountryData,
  ListMatchingData,
  Operation,
  TransactionalAnalysisData,
  Request,
  Response,
  Validator,
  ValidatorManualReview,
  UserManualReview,
  BasicReviewValidation,
} from 'types/Transaction';
import { Grid, Margin, CardBox, Notification } from '@gbm/queen-ui-guidelines';
import Feature from 'providers/FeatureProvider/Feature';
import { TransactionStatus } from 'constants/transactionStatus';
import ModalVerification from './ModalVerification';
import ConfirmManualReviewDialog from './ConfirmManualReviewDialog';
import ValidationsToSave from './SidebarValidationsBody';
import { ManualReviewBody } from 'utils/request/transactions';
import { TransactionTypes } from 'utils/transactionsUtils';
import CountriesValidator from './CountriesValidator';
import ListMatchingValidator from './ListMatchingValidator';
import TransactionalAnalysisValidator from './TransactionalAnalysisValidator';
import { TransactionsToSave } from 'types/TransactionsState';
import { TransactionValidationToSave } from 'types/TransactionValidationToSave';
import { nanoid } from 'nanoid';
import TransactionSkeleton from './TransactionSkeleton';
import ConfirmSetOnReviewDialog from './ConfirmSetOnReviewDialog';
import ModalConfirmCancelation from './ModalConfirmCancelation';

const TransactionDetailV2 = () => {
  const [countriesValidatorData, setCountriesValidatorData] = useState<
    CountryData[] | null
  >(null);
  const [listMatchingValidatorData, setListMatchingValidatorData] = useState<
    ListMatchingData[] | null
  >(null);
  const [
    transactionalAnalysisValidatorData,
    setTransactionalAnalysisValidatorData,
  ] = useState<TransactionalAnalysisData | null>(null);
  const [openSlide, setOpenSlide] = useState(false);
  const [selectedValidator, setSelectedValidator] = useState<Validator | null>(
    null,
  );
  const [isOpenConfirmReviewDialog, setIsOpenConfirmReviewDialog] =
    useState<boolean>(false);
  const [isOpenConfirmSetOnReviewDialog, setIsOpenConfirmSetOnReviewDialog] =
    useState<boolean>(false);
  const [
    isOpenSuccessMessageChangeStatus,
    setIsOpenSuccessMessageChangeStatus,
  ] = useState<boolean>(false);
  const [isOpenErrorMessageChangeStatus, setIsOpenErrorMessageChangeStatus] =
    useState<boolean>(false);

  const [isOpenErrorMessage, setIsOpenErrorMessage] = useState<boolean>(false);
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] =
    useState<boolean>(false);
  const [dataValidationsToShow, setDataValidationsToShow] =
    useState<TransactionsToSave>({
      countries: [],
      listmatching: [],
      txn: [],
    });
  const [dataValidationsToFill, setDataValidationsToFill] = useState<
    TransactionValidationToSave[]
  >([]);
  const [verificationToUpdate, setVerificationToUpdate] =
    useState<ValidatorManualReview | null>(null);
  const [isOpenModalConfirmCancelation, setIsOpenModalConfirmCancelation] =
    useState<boolean>(false);
  const [isOpenModalVerification, setIsOpenModalVerification] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const {
    getTransactionById,
    createManualReview,
    resetCreateManualReview,
    resetGetTransactions,
    resetSetStatusToTransaction,
    setStatusToTransaction,
  } = useDispatcherTransactions();
  const {
    transactionState,
    createManualReviewState,
    transactionsToSave,
    setStatusToTransactionState,
  } = useSelectorsTransactions();
  const { updateTransactionToSave } = useDispatcherTransactions();

  useEffect(() => {
    resetCreateManualReview();
    resetGetTransactions();
    setDataValidationsToFill([]);
  }, []);

  useEffect(() => {
    if (transactionsToSave) {
      setDataValidationsToShow(transactionsToSave);
    }
  }, [transactionsToSave]);

  useEffect(() => {
    if (id) {
      getTransactionById(id);
    }
  }, [id, getTransactionById]);

  useEffect(() => {
    if (transactionState.item) {
      if (transactionState.item?.body.countries) {
        setCountriesData();
      }

      if (transactionState.item?.validators) {
        setListMatchingData();
        setTransactionalAnalysisData();
      }

      if (transactionState.item) {
        const txTransaction = transactionState.item?.validators.find(
          (validator) => validator.code === TransactionTypes.TXN,
        );

        setSelectedValidator(
          txTransaction || transactionState.item?.validators[0],
        );
      }
    } else {
      setDataValidationsToFill([]);
    }
  }, [transactionState.item]);

  const setTransactionOnReview = () => {
    const dataRequest: BasicReviewValidation = {
      status: TransactionStatus.underReview,
    };
    setStatusToTransaction(dataRequest, id ?? '');
  };

  const cancelTransactionOnReview = (values: any) => {
    const dataRequest: BasicReviewValidation = {
      status: TransactionStatus.canceled,
      details: {
        comments: values.comment,
        complementaryComments: values.supplementaryComment ?? '',
      },
    };
    setStatusToTransaction(dataRequest, id ?? '');
  };

  const setTransactionalAnalysisData = () => {
    let transactionalAnalysisData: TransactionalAnalysisData | undefined;

    const operation: Operation | undefined =
      transactionState.item?.body?.operation;
    const transactionalAnalysis: Validator | undefined =
      transactionState.item?.validators.find(
        (validator) => validator.code === 'txn',
      );

    if (transactionalAnalysis && operation) {
      const transactionalAnalysisRequest: Request | undefined =
        transactionalAnalysis.request as Request;
      const transactionalAnalysisResponse: Response | undefined =
        transactionalAnalysis.response as Response;

      if (transactionalAnalysisRequest && transactionalAnalysisResponse) {
        const manualReviewDetail =
          transactionalAnalysis.manualReview?.details.find(
            (detailManualReview) =>
              detailManualReview.requestId ===
              transactionalAnalysisResponse.requestId,
          );
        transactionalAnalysisData = {
          request: transactionalAnalysisRequest,
          response: transactionalAnalysisResponse,
          operation,
          manualReview: manualReviewDetail || undefined,
          userManualReview: manualReviewDetail
            ? ({
                user: transactionalAnalysis.manualReview?.user,
                userName: transactionalAnalysis.manualReview?.userName,
              } as UserManualReview)
            : undefined,
        };

        setTransactionalAnalysisValidatorData(transactionalAnalysisData);

        if (
          transactionalAnalysis.status === TransactionStatus.manualReview &&
          transactionalAnalysisResponse.matchAction ===
            TransactionStatus.manualReview &&
          transactionState?.item?.status === TransactionStatus.manualReview
        ) {
          const isValidationAdded = dataValidationsToFill.find(
            (validation) =>
              validation.id === transactionalAnalysisResponse.requestId,
          );

          if (!isValidationAdded) {
            setDataValidationsToFill(
              (dataValidations: TransactionValidationToSave[]) => [
                ...dataValidations,
                {
                  id: transactionalAnalysisResponse.requestId,
                  completed: false,
                },
              ],
            );
          }
        }
      }
    }
  };

  const setListMatchingData = () => {
    const listMatchingData: ListMatchingData[] = [];
    const listMatchingValidator: Validator | undefined =
      transactionState.item?.validators.find(
        (validator) => validator.code === 'listmatching',
      );

    if (listMatchingValidator) {
      const listMatchingRequest: Request[] =
        listMatchingValidator.request as Request[];
      const listMatchingResponse: Response[] =
        listMatchingValidator.response as Response[];

      listMatchingRequest?.forEach((listMatching, index) => {
        const listMatchingValidatorResponse = listMatchingResponse[index];
        if (listMatchingValidatorResponse) {
          const manualReviewDetail =
            listMatchingValidator.manualReview?.details.find(
              (detailManualReview) =>
                detailManualReview.requestId ===
                listMatchingValidatorResponse.requestId,
            );
          const listMatchingDataItem: ListMatchingData = {
            ...listMatching.body,
            validator: listMatchingValidatorResponse,
            manualReview: manualReviewDetail || undefined,
            userManualReview: manualReviewDetail
              ? ({
                  user: listMatchingValidator.manualReview?.user,
                  userName: listMatchingValidator.manualReview?.userName,
                } as UserManualReview)
              : undefined,
            frontendId: nanoid(),
          };
          listMatchingData.push(listMatchingDataItem);
        }
      });

      if (listMatchingValidator.status === TransactionStatus.manualReview) {
        listMatchingData.forEach((listMatching: ListMatchingData) => {
          if (
            listMatching.validator.matchAction ===
            TransactionStatus.manualReview
          ) {
            const isValidationAdded = dataValidationsToFill.find(
              (validation) =>
                validation.id === listMatching.validator.requestId,
            );

            if (!isValidationAdded) {
              setDataValidationsToFill((dataValidations: any) => [
                ...dataValidations,
                {
                  id: listMatching.validator.requestId,
                  completed: false,
                },
              ]);
            }
          }
        });
      }
    }
    setListMatchingValidatorData(listMatchingData);
  };

  const setCountriesData = () => {
    const countriesData: CountryData[] = [];
    const countries: CountryData[] | undefined =
      transactionState.item?.body.countries;
    const countriesValidator: Validator | undefined =
      transactionState.item?.validators.find(
        (validator) => validator.code === 'countries',
      );

    if (countries && countriesValidator) {
      const countriesResponse: Response[] =
        countriesValidator.response as Response[];

      countries.forEach((country, index) => {
        const countryValidatorResponse = countriesResponse[index];

        if (countryValidatorResponse) {
          const manualReviewDetail =
            countriesValidator.manualReview?.details.find(
              (detailManualReview) =>
                detailManualReview.requestId ===
                countryValidatorResponse.requestId,
            );
          const countryData: CountryData = {
            ...country,
            validator: countryValidatorResponse,
            manualReview: manualReviewDetail || undefined,
            userManualReview: manualReviewDetail
              ? ({
                  user: countriesValidator.manualReview?.user,
                  userName: countriesValidator.manualReview?.userName,
                } as UserManualReview)
              : undefined,
            frontendId: nanoid(),
            details: country.details,
          };

          countriesData.push(countryData);
        }
      });

      if (countriesValidator?.status === TransactionStatus.manualReview) {
        countriesData.forEach((country: CountryData) => {
          if (
            country.validator.matchAction === TransactionStatus.manualReview
          ) {
            const isValidationAdded = dataValidationsToFill.find(
              (validation) => validation.id === country.validator.requestId,
            );
            if (!isValidationAdded) {
              setDataValidationsToFill(
                (dataValidations: TransactionValidationToSave[]) => [
                  ...dataValidations,
                  {
                    id: country.validator.requestId,
                    completed: false,
                  },
                ],
              );
            }
          }
        });
      }
    }
    setCountriesValidatorData(countriesData);
  };

  useEffect(() => {
    if (createManualReviewState.loaded && !createManualReviewState.error) {
      setIsOpenConfirmReviewDialog(false);
      setIsOpenSuccessMessage(true);
      setTimeout(() => {
        getTransactionById(id);
        resetCreateManualReview();
        setDataValidationsToFill([]);
        setDataValidationsToShow({
          countries: [],
          listmatching: [],
          txn: [],
        });
        setIsOpenSuccessMessage(false);
      }, 3000);
    } else if (
      createManualReviewState.loaded &&
      createManualReviewState.error
    ) {
      setIsOpenErrorMessage(true);
      setTimeout(() => {
        setIsOpenErrorMessage(false);
      }, 3000);
    }
  }, [createManualReviewState.loaded]);

  useEffect(() => {
    if (
      setStatusToTransactionState.loaded &&
      !setStatusToTransactionState.error
    ) {
      setIsOpenConfirmSetOnReviewDialog(false);
      setIsOpenSuccessMessageChangeStatus(true);
      setIsOpenModalConfirmCancelation(false);
      setTimeout(() => {
        getTransactionById(id);
        resetSetStatusToTransaction();
        setIsOpenSuccessMessageChangeStatus(false);
      }, 3000);
    } else if (
      setStatusToTransactionState.loaded &&
      setStatusToTransactionState.error
    ) {
      setIsOpenErrorMessageChangeStatus(true);
      setTimeout(() => {
        setIsOpenErrorMessageChangeStatus(false);
      }, 3000);
    }
  }, [setStatusToTransactionState.loaded]);

  const handleUpdateVerification = (validator: ValidatorManualReview) => {
    setVerificationToUpdate(validator);
    setIsOpenModalVerification(true);
  };

  const handleAddVerification = (dataValidation: any) => {
    const data: ValidatorManualReview = {
      code: verificationToUpdate
        ? verificationToUpdate.code
        : selectedValidator?.code || '',
      status: dataValidation?.dropdownStatus?.id,
      details: {
        comments: dataValidation?.comment,
        complementaryComments: dataValidation?.supplementaryComment,
      },
      requestId: verificationToUpdate?.requestId,
      labelToShow: verificationToUpdate?.labelToShow,
      id: selectedValidator?.code || '',
    };

    updateTransactionToSave({
      validator: verificationToUpdate?.code,
      data: { ...data },
    });
    setIsOpenModalVerification(false);
    setVerificationToUpdate(null);
  };

  const verifyAllValidationsChecked = () => {
    return dataValidationsToFill.every((validation) => validation.completed);
  };

  const verifyManualReviewData = () => {
    const verifyAllChecked = verifyAllValidationsChecked();

    if (verifyAllChecked) {
      const verificationData: ManualReviewBody = { validators: [] };
      verificationData.validators = [
        ...dataValidationsToShow.countries,
        ...dataValidationsToShow.listmatching,
        ...dataValidationsToShow.txn,
      ];
      transactionState.item?.id || '';
      createManualReview(transactionState.item?.id || '', verificationData);
    }
  };
  const isSelectedValidatorFilled = (selectedTransactionId: string) => {
    const validation = dataValidationsToFill.find(
      (validation) => validation.id === selectedTransactionId,
    );

    return validation?.completed ?? false;
  };

  const switchValidator = () => {
    switch (selectedValidator?.code) {
      case TransactionTypes.COUNTRIES:
        return (
          <CountriesValidator
            countriesData={countriesValidatorData}
            updateValidationsToFill={setDataValidationsToFill}
            isSelectedValidatorFilled={isSelectedValidatorFilled}
            transactionStatus={transactionState?.item?.status}
          />
        );

      case TransactionTypes.LIST_MATCHING:
        return (
          <ListMatchingValidator
            listMatchingData={listMatchingValidatorData}
            updateValidationsToFill={setDataValidationsToFill}
            isSelectedValidatorFilled={isSelectedValidatorFilled}
            transactionStatus={transactionState?.item?.status}
          />
        );

      case TransactionTypes.TXN:
        return (
          <TransactionalAnalysisValidator
            transactionalAnalysisData={transactionalAnalysisValidatorData}
            updateValidationsToFill={setDataValidationsToFill}
            isSelectedValidatorFilled={isSelectedValidatorFilled}
            transactionStatus={transactionState?.item?.status}
          />
        );
      default:
        return (
          <CountriesValidator
            countriesData={countriesValidatorData}
            updateValidationsToFill={setDataValidationsToFill}
            isSelectedValidatorFilled={isSelectedValidatorFilled}
            transactionStatus={transactionState?.item?.status}
          />
        );
    }
  };

  return (
    <>
      <Feature name="transactionsRead">
        <div
          className={clsx(styles.contentPageTxDetail, {
            [styles.contentPageTxDetailOpen]: openSlide,
          })}
        >
          <div className={styles.container}>
            <Grid>
              <Margin side="bottom" xs={24}>
                <Header
                  id={transactionState?.item?.id}
                  status={transactionState?.item?.status}
                  createdAt={transactionState.item?.createdAt}
                  updatedAt={transactionState.item?.updatedAt}
                  loading={transactionState.loading}
                  setStatusToTransaction={() =>
                    setIsOpenConfirmSetOnReviewDialog(true)
                  }
                  cancelTransaction={() =>
                    setIsOpenModalConfirmCancelation(true)
                  }
                />
              </Margin>
              <Margin side="bottom" xs={24}>
                <Validators
                  validators={transactionState.item?.validators}
                  setSelectedValidator={setSelectedValidator}
                  selectedValidator={selectedValidator}
                  loading={transactionState.loading}
                />
              </Margin>

              <Margin side="bottom" xs={24}>
                <CardBox fullHeight>
                  {transactionState.loading ? (
                    <TransactionSkeleton />
                  ) : (
                    switchValidator()
                  )}
                </CardBox>
              </Margin>
            </Grid>
            <ModalConfirmCancelation
              onClose={() => {
                setIsOpenModalConfirmCancelation(false);
                resetSetStatusToTransaction();
              }}
              onSubmit={cancelTransactionOnReview}
              isOpen={isOpenModalConfirmCancelation}
            />
            <ModalVerification
              dataVerification={verificationToUpdate}
              onClose={() => {
                setIsOpenModalVerification(false);
                setVerificationToUpdate(null);
              }}
              onSubmit={handleAddVerification}
              isOpen={isOpenModalVerification}
            />
            <ConfirmManualReviewDialog
              isOpen={isOpenConfirmReviewDialog}
              onClose={() => setIsOpenConfirmReviewDialog(false)}
              onConfirm={verifyManualReviewData}
              loading={createManualReviewState.loading}
              error={Boolean(createManualReviewState.error)}
            />
          </div>
          <ConfirmSetOnReviewDialog
            isOpen={isOpenConfirmSetOnReviewDialog}
            onClose={() => {
              setIsOpenConfirmSetOnReviewDialog(false);
              resetSetStatusToTransaction();
            }}
            onConfirm={setTransactionOnReview}
            loading={setStatusToTransactionState.loading}
            error={Boolean(setStatusToTransactionState.error)}
          />
          <SideView title="" openSlide={openSlide} setOpenSlide={setOpenSlide}>
            <ValidationsToSave
              dataValidationsToShow={dataValidationsToShow}
              loading={false}
              handleUpdateVerification={handleUpdateVerification}
              checkValidationsChecked={verifyAllValidationsChecked}
              setIsOpenConfirmReviewDialog={setIsOpenConfirmReviewDialog}
              countriesValidatorData={countriesValidatorData}
              listMatchingValidatorData={listMatchingValidatorData}
              transactionalAnalysisValidatorData={
                transactionalAnalysisValidatorData
              }
            />
          </SideView>
        </div>
      </Feature>
      <Notification
        data-testid="notification-associate-party-error"
        open={isOpenSuccessMessage}
        color="success"
        colorInherit
      >
        The manual review was completed successfully
      </Notification>
      <Notification
        data-testid="notification-associate-party-error"
        color="danger"
        colorInherit
        open={isOpenErrorMessage}
      >
        The manual review could not be completed, try again later.
      </Notification>
      <Notification
        data-testid="notification-associate-party-error"
        open={isOpenSuccessMessageChangeStatus}
        color="success"
        colorInherit
      >
        Transaction status was successfully updated
      </Notification>
      <Notification
        data-testid="notification-associate-party-error"
        color="danger"
        colorInherit
        open={isOpenErrorMessageChangeStatus}
      >
        Error when changing status.
      </Notification>
    </>
  );
};

export default TransactionDetailV2;
