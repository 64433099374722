import {
  type PropsWithChildren,
  type FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { Typography } from '@gbm/queen-ui-guidelines';
import clsx from 'clsx';
import { FaArrowLeft } from 'react-icons/fa';

import styles from './styles.module.scss';

interface SideViewProps extends PropsWithChildren {
  openSlide: boolean;
  setOpenSlide: Dispatch<SetStateAction<boolean>>;
  title: string;
}

const SideView: FC<SideViewProps> = ({
  setOpenSlide,
  openSlide,
  title,
  children,
}) => {
  return (
    <div
      className={clsx(styles.sliderContainer, {
        [styles.sliderContainerOpen]: openSlide,
      })}
    >
      <FaArrowLeft
        className={clsx(styles.arrow, openSlide && styles.arrowReversed)}
        onClick={() => setOpenSlide(!openSlide)}
      />
      {openSlide && (
        <div className={styles.contentContainer}>
          <Typography component="h2" weight="semibold">
            {title}
          </Typography>
          {children}
        </div>
      )}
    </div>
  );
};

export default SideView;
