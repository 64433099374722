import {
  Margin,
  Typography,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Skeleton,
  DisplayMessage,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import ValidationToShowCard from 'pages/TransactionDetailV2/ValidationToShowCard';
import { FC, useEffect, useState } from 'react';
import { SavedValidations } from 'types/SavedValidations';
import {
  CountryData,
  ListMatchingData,
  ManualReviewDetail,
  TransactionalAnalysisData,
} from 'types/Transaction';
import { getCodeToDisplay, TransactionTypes } from 'utils/transactionsUtils';

type SavedManualReviewsProps = {
  loading: boolean;
  listOfSavedManualReviews: SavedValidations;
};

const SavedManualReviews: FC<SavedManualReviewsProps> = ({
  loading,
  listOfSavedManualReviews,
}) => {
  const renderSavedManualReviews = () => {
    return (
      <Accordion collapsible multiple key={nanoid()}>
        {listOfSavedManualReviews.countries.map((value: ManualReviewDetail) => (
          <AccordionItem key={nanoid()}>
            <AccordionHeader
              controlLabel={getCodeToDisplay(TransactionTypes.LIST_MATCHING)}
              className="d-flex justify-content-between"
            ></AccordionHeader>
            <AccordionPanel>
              <div key={nanoid()} className="w-100">
                <ValidationToShowCard
                  loading={loading}
                  status={value.status}
                  comments={value.comments ?? ''}
                  complementaryComments={value?.complementaryComments ?? ''}
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}

        {listOfSavedManualReviews.listMatching.map(
          (value: ManualReviewDetail) => (
            <AccordionItem key={nanoid()}>
              <AccordionHeader
                controlLabel={getCodeToDisplay(TransactionTypes.LIST_MATCHING)}
                className="d-flex justify-content-between"
              ></AccordionHeader>
              <AccordionPanel>
                <div key={nanoid()} className="w-100">
                  <ValidationToShowCard
                    loading={loading}
                    status={value.status}
                    comments={value?.comments ?? ''}
                    complementaryComments={value?.complementaryComments ?? ''}
                  />
                </div>
              </AccordionPanel>
            </AccordionItem>
          ),
        )}

        <>
          {Object.keys(listOfSavedManualReviews.txn ?? {}).length > 0 && (
            <>
              <AccordionItem key={nanoid()}>
                <AccordionHeader
                  controlLabel={getCodeToDisplay(TransactionTypes.TXN)}
                  className="d-flex justify-content-between"
                ></AccordionHeader>
                <AccordionPanel>
                  <div key={nanoid()} className="w-100">
                    <ValidationToShowCard
                      loading={loading}
                      status={listOfSavedManualReviews.txn?.status ?? ''}
                      comments={listOfSavedManualReviews.txn?.comments ?? ''}
                      complementaryComments={
                        listOfSavedManualReviews.txn?.complementaryComments ??
                        ''
                      }
                    />
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
        </>
      </Accordion>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex justify-content-between">
          <Typography component="h4" align="left">
            Saved Validations
          </Typography>
        </div>
      </Margin>
      <Margin side="bottom" xs={24}>
        {renderSavedManualReviews()}
      </Margin>
    </>
  );
};

export default SavedManualReviews;
