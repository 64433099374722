import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';

import { LOCAL_STORAGE } from 'constants/localStorage';
import { RequestConfig } from 'types/RequestConfig';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  if (isJsonResponse(response)) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }

  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  class CustomError extends Error {
    response: Response | undefined;

    status: number | undefined;

    code: number | undefined;

    id: string | undefined;

    requestId: string | undefined | null;

    notifications: string | undefined;
  }

  const error = new CustomError(response.statusText);

  error.response = response;
  error.status = response.status;

  if (isJsonResponse(response)) {
    return response
      .json()
      .then((data) => {
        error.code = data.code;
        error.id = data.id;
        error.message = data.message;
        error.requestId = response.headers.get('x-amzn-RequestId');

        if (data.details && data.details.length > 0) {
          error.notifications = data.details;
        }

        throw error;
      })
      .catch(() => {
        throw error;
      });
  }

  throw error;
}

function isJsonResponse(response: any) {
  const contentType = response.headers.get('content-type');

  if (
    contentType &&
    (contentType.includes('application/json') ||
      contentType.includes('application/hal+json'))
  ) {
    return true;
  }

  return false;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {object} [auth]    Wether the request is authenticated or not
 *
 * @return {object}           The response data
 */
export default function request(
  url: string,
  options: RequestConfig,
  auth = true,
) {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': 'es-MX',
      ...options.headers,
    },
    ...options,
  } as RequestConfig;

  if (options.body) {
    requestOptions.body = auth
      ? JSON.stringify({ ...options.body })
      : qs.stringify({ ...options.body });
  }

  if (auth) {
    requestOptions.headers!.Authorization = `Bearer ${localStorage.getItem(
      LOCAL_STORAGE.TOKEN,
    )}`;
  }

  return fetch(url, requestOptions as RequestInit)
    .then(checkStatus)
    .then(parseJSON);
}
